import { FieldTimeOutlined } from "@ant-design/icons";
import { Card, message, Steps } from "antd";
import classNames from "classnames";
import { Fragment, useEffect } from "react";
import { paymentStatus } from "../../../helpers/order";
import marbleApi from "../../../services/http/marbleApi";
import styles from "../style.less";

const { Step } = Steps;

function OrderProgress(props) {
  const { order, syncOrder } = props;

  const customDot = (dot, { status }) => {
    if (status === "process") {
      return <span>{dot}</span>;
    }

    return dot;
  };
  const desc1 = (
    <div className={classNames(styles.textSecondary, styles.stepDescription)}>
      <Fragment>
        by <u>{order?.User.name}</u>
      </Fragment>
      <div> on {order?.createdAt}</div>
    </div>
  );
  const desc2 = (
    <div className={styles.stepDescription}>
      <div>
        <div> on {order?.quotes[order?.quotes.length - 1]?.createdAt}</div>
      </div>
    </div>
  );

  useEffect(() => {
    if (order?.saleOrder === null && order?.payments.length > 0) {
      async function createSaleOrder() {
        try {
          await marbleApi.http.post(marbleApi.urls.saleOrder.create, {
            order_id: order?.id,
          });
          message.success("Sales order added");
          await syncOrder();
          return true;
        } catch (error) {
          message.error(error.message);
          return false;
        }
      }
      createSaleOrder();
    }
  }, [order]);

  return (
    <Card
      title={
        <span>
          <h3>
            <FieldTimeOutlined /> Order Progress
          </h3>
        </span>
      }
      style={{
        marginBottom: 24,
      }}
    >
      <Steps progressDot={customDot} current={order?.stage}>
        <Step title="Order Created" description={desc1} />
        <Step title="Quote Created" description={desc2} />
        <Step
          title="Payment"
          description={
            <div className={styles.stepDescription}>
              <div>
                <div> {paymentStatus(order)}% Payment done.</div>
              </div>
            </div>
          }
        />
        <Step
          title="Sale Order"
          /*description={
            <div className={styles.stepDescription}>
              <div>
                <div> Sale Order Generated.</div>
                <div> Delivery Details Pending.</div>
                <div> on 2016-12-12 12:32</div>
              </div>
            </div>
          }*/
        />
        <Step
          title="Order Delivery"
          /*description={
            <div className={styles.stepDescription}>
              <div>
                <div> Delivered to - 14554 Keswick St, Van Nuys, CA 91405</div>
                <div>
                  {" "}
                  By <u>Drake Johnson</u> from Newyork movers
                </div>
                <div> on 2016-12-12 12:32</div>
              </div>
            </div>
          }*/
        />
        <Step
          title="Order Complete"
          description={
            <div className={styles.stepDescription}>
              {order?.stage === 6 &&
                <div style={{color:'red'}}>
                  <div> Closed lost</div>
                </div>}
                {order?.stage === 5 &&
                <div style={{color:'green'}}>
                  <div> Closed won</div>
                </div>}
            </div>
          }
        />
      </Steps>
    </Card>
  );
}

export default OrderProgress;
