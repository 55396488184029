import marbleApiService from "../../services/http/marbleApi";
import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import React, { useState } from "react";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function ImageUpload(props) {
  const { callback, limit, images, slug, slab, newArrivalId } = props;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState(images);
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList }) => {
    console.log(fileList);
    setFileList(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadFile = async (componentsData) => {
    const hide = message.loading("uploading...");
    let formData = new FormData();
    formData.append("file", componentsData.file);
    formData.append("slug", slug);
    formData.append("slabId", slab?.id);
    formData.append("newArrivalId", newArrivalId);
    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          componentsData.onProgress({ percent: progress });
        },
      };
      const response = await marbleApiService.http.post(
        marbleApiService.urls.asset.upload,
        formData,
        config
      );
      hide();
      setFileList([
        {
          uid: "na",
          name: "",
          status: "done",
          url: response?.body?.url,
        },
      ]);
      message.success("uploaded successfully");
      if (callback) callback("upload", response?.body);
      componentsData.onSuccess();
    } catch (error) {
      hide();
      componentsData.onError("Error uploading image");
      message.error(error.message || error?.data?.message);
    }
  };

  const removeFile = (file) =>
    marbleApiService.http
      .post(marbleApiService.urls.asset.remove, {
        url: file.url,
        id: file.uid,
      })
      .then((data) => {
        if (callback) callback("delete", null);
      })
      .catch((error) => {
        message.error(error.message);
      });

  return (
    <>
      <Upload
        customRequest={uploadFile}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={removeFile}
        onChange={handleChange}
        className="image-upload"
      >
        {fileList.length >= limit ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
