/* eslint-disable react-hooks/exhaustive-deps */
import { saveToLocalStorage } from "../../../helpers";
import { Button, Form, Input, Modal, Spin, Result, message } from "antd";
import React, { useState, useEffect } from "react";
import marbleApi from "../../../services/http/marbleApi";
import { useNavigate } from "react-router-dom";

export default function AccountSetUp(props) {
  const [acountSetUpModal, setAcountSetUpModal] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);
  const [validating, setvalidating] = useState(true);
  const [updating, setupdating] = useState(false);
  const [setup, setSetup] = useState(false);
  const [user, setuser] = useState({});

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const token = props.query.get("token");

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  useEffect(() => {
    async function verifyUrl() {
      try {
        const response = await marbleApi.http.post(marbleApi.urls.auth.verify, {
          token,
        });
        if (response) {
          saveToLocalStorage("user", response);
          setuser({
            name: response.name,
            email: response.email,
            id: response.id,
          });
          setTokenValid(true);
        }
      } catch (error) {
        message.error(error?.message);
        setTokenValid(false);
      } finally {
        setvalidating(false);
      }
    }
    if (token && !tokenValid) verifyUrl();
  }, []);

  const onFinish = async (values) => {
    setupdating(true);
    try {
      const { name, password } = values;
      const response = await marbleApi.http.post(
        marbleApi.urls.user.update(user?.id),
        {
          data: {
            name,
            password,
          },
        }
      );
      if (response) {
        setSetup(true);
        localStorage.clear();
        message.success(response.message);
      }
    } catch (error) {
      message.error(error?.message);
    } finally {
      setupdating(false);
    }
  };

  return (
    <div>
      <Modal
        title="🚀 Set up your account"
        centered
        visible={acountSetUpModal}
        onCancel={() => setAcountSetUpModal(false)}
        footer={null}
        maskClosable={false}
        width={800}
      >
        {validating && (
          <div style={{ textAlign: "center" }}>
            <Spin tip="Validating request" />
          </div>
        )}

        {!validating && !tokenValid && (
          <Result
            status="error"
            title="Invalid Request"
            subTitle="Your link might have been expired. Please contact admin to send you a new link"
          ></Result>
        )}

        {setup && (
          <Result
            status="success"
            title="Your account was successfully updated."
            subTitle="You can now login to your account"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </Button>,
            ]}
          />
        )}

        {!validating && tokenValid && !setup && (
          <Form
            {...formItemLayout}
            form={form}
            name="account-setup"
            onFinish={onFinish}
            initialValues={{}}
            scrollToFirstError
          >
            <Form.Item name="email" label="E-mail" initialValue={user?.email}>
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              initialValue={user?.name}
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" loading={updating}>
                Continue
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}
