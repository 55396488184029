import { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ProFormText } from "@ant-design/pro-components";
import { Card, Alert, Button, message,Tag,Modal,Space } from "antd";
import marbleApi from "../../../services/http/marbleApi";

function ProductEdit(props) {
    const {id, productEditPreview, setProductEditPreview,product } = props;
    const [tags, setTags] = useState(product?.tags?.split(','));
    const [tag, setTag] = useState(null);

    useEffect(() => {
      setTags(product?.tags?.split(','));
    }, [product]);
    
    // Remove Tag
    const handleRemove = async (item) => {
        const removeMe = tags.filter((i) => i !== item);
        console.log(removeMe)
        setTags(removeMe)
     }

    // update product
    const handleUpdate = async () => {
      const hide = message.loading("updating product");
      try {
        const result = await marbleApi.http.post(
          marbleApi.urls.products.updateProduct,
          {
            id: id,
            data: { tags: tags },
            product:product
          }
        );
        if (result) {
          hide();
          message.success("updated successfully");
          setProductEditPreview(false)
          return true;
        }
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    };

  return (
    <Modal
      visible={productEditPreview}
      title="Edit Product"
      okText="Save"
      onOk={handleUpdate}
      onCancel={() => setProductEditPreview(false)}
      width="60vw"
    >
      <ProFormText
        label="Tags"
        name="tags"
        addonAfter={
          <Button
            type="primary"
            onClick={() => setTags([...tags, tag])}
            disabled={!tag}
          >
            Add
          </Button>
        }
        onChange={(e) => setTag(e.target.value)}
      />
      {tags?.map((item) => (
        <Tag
          closable
          onClose={(e) => {
            e.preventDefault();
            handleRemove(item);
          }}
        >
          {item}
        </Tag>
      ))}
    </Modal>
  );
}

export default ProductEdit