import ProTable from "@ant-design/pro-table";
import { Alert, message } from "antd";
import { useState, useEffect } from "react";
import { invoiceCheck } from "../../../helpers/order";

function ProductsInvoice(props) {
  const { order, setProInv, setTotal } = props;
  const inv = invoiceCheck(order);


  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [type, setType] = useState("info");
  const [selectRows, setSelectedRows] = useState();

  useEffect(() => {
    if (selectRows?.length > 0) {
      const sum = selectRows
        .map((i) => i.finalPrice)
        .reduce((a, b) => a + b);
      const tax = (order?.tax / 100) * sum
      setInvoiceTotal((sum+tax).toFixed(2));
      if (sum > inv) {
        setType("error");
        message.error(
          "You have not done enough payment to purchase this much products, please remove some products from invoice or complete more payment"
        );
      } else {
        setType("info");
        setProInv(selectRows);
        setTotal(sum);
      }
    }
    else {
      setInvoiceTotal(0);
      setType("info");
      setTotal(null)
    }

  }, [selectRows]);


  return (
    <>
      {" "}
      <Alert
        message={`Invoice Total Inc Tax $${invoiceTotal}`}
        type={type}
        action={<span>{`Payment Done $${inv}`}</span>}
      />
      <ProTable
        //------------------------------------------------------------
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            sorter: true,
            render: (dom, entity) => {
              return order ? <p>{entity.Slab?.name}</p> : <span>{dom}</span>;
            },
          },
          {
            title: "Slab No.",
            dataIndex: "slabId",
            valueType: "textarea",
            render: (dom, entity) => {
              return <span>{order ? entity.Slab?.slabId : dom}</span>;
            },
          },
          {
            title: "Block No.",
            dataIndex: "blockIdSAP",
            valueType: "textarea",
            render: (dom, entity) => {
              return <span>{order ? entity.Slab?.blockIdSAP : dom}</span>;
            },
          },

          {
            title: "Size",
            dataIndex: "existing",
            valueType: "textarea",
            render: (dom, entity) => {
              return order
                ? `${
                    entity.Slab?.size
                      ? entity.Slab?.size
                      : entity.Slab?.existing
                  }`
                :   `${entity.size ? entity.size : entity.existing}`;
                 // `...`;
            },
          },

          {
            title: "Price / sqf",
            dataIndex: "price",
            valueType: "textarea",
            render: (dom, entity) => {
              return <span>{`$${entity.price.price}`}</span>;
            },
          },

          {
            title: "Discount / sqf",
            dataIndex: "discount",
            valueType: "textarea",
            render: (dom, entity) => {
              return <span>{entity.discount || 0}</span>;
            },
          },

          {
            title: "Final Price",
            dataIndex: "price",
            valueType: "textarea",
            render: (dom, entity) => {
              // entity.finalPrice = getFinalPrice(entity);
              return <span>{`$${entity.finalPrice}`}</span>;
            },
          },
        ]}
        //-------------------------------------------------------------

        headerTitle={false}
        search={false}
        options={false}
        rowKey="slabid"
        dataSource={order?.orderSlabs}
        pagination={false}
        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
            // const sum = selectedRows
            //   .map((i) => i.finalPrice) 
            //   .reduce((a, b) => a + b);
            // setInvoiceTotal(sum);
            // if (sum > inv) {
            //   console.log(inv, "invvvvv");
            //   console.log(invoiceTotal, "invoiceTotal");
            //   setType("error");
            //   message.error(
            //     "You have not done enough payment to purchase this much products, please remove some products from invoice or complete more payment"
            //   );
            // } else {
            //   setType("info");
            //   setProInv(selectedRows);
            //   setTotal(sum);
            // }
          },
        }}
      />
    </>
  );
}

export default ProductsInvoice;
