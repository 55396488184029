import ProForm, { ProFormText, ProFormSwitch } from "@ant-design/pro-form";
import { message } from "antd";
import React, { useState } from "react";
import marbleApi from "../../../../services/http/marbleApi";
import styles from "./BaseView.less";

const EmailView = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { currentUser } = props;
  const [form] = ProForm.useForm();

  const handleFinish = async (values) => {
    const hide = message.loading("Updating");

    console.log(values);

    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.user.update(currentUser?.id),
        {
          data: values,
        }
      );
      hide();
      message.success("Updated successsfully");
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };

  return (
    <div className={"EmailView"}>
      <>
        <div className={"left"}>
          <ProForm
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            submitter={{
              resetButtonProps: {
                style: {
                  display: "none",
                },
              },
              submitButtonProps: {
                children: "Submit",
              },
            }}
            initialValues={{ ...currentUser }}
            hideRequiredMark
          >
            <ProFormSwitch
              name="custom_smtp"
              label="Custom Email"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            />
            <ProFormText width="md" name="smtp_host" label="SMTP Host" />
            <ProFormText
              width="md"
              name="smtp_username"
              label="SMTP Username"
            />
            <ProFormText
              width="md"
              name="smtp_password"
              label="SMTP Password"
            />
          </ProForm>
        </div>
      </>
    </div>
  );
};

export default EmailView;
