
import {Progress, message,Alert, Card,Typography, Badge, Button, Col, Row, Statistic } from 'antd';
import React, { useEffect, useState } from 'react';
import {ExclamationCircleFilled,CheckCircleFilled,  ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import './index.css';
const Stats = ({ data }) => {
  console.log(data,"data")
  

  return (

 
      <Row justify="start" gutter={24} style={{margin:"0px 0px"}}>

              {/* ---------------active Orders------------------------ */}
       
              <Card className="site-statistic-demo-card" >
          <Row gutter={16}>
            <Col span={0.1}>
              <Badge size="large" status="processing"  />
            </Col>
            <Col span={15.9}>
              <Statistic title="Active Orders" value={data?.orders?._count?.id || 0} />
            </Col>
          </Row>
        </Card>

        {/* ---------------Total Revenue------------------------ */}
        
        <Card className="site-statistic-demo-card">
          <Row gutter={16}>
            <Col span={0.1}>
              <Badge size="large" status="success" />
            </Col>
            <Col span={15.9}>
              <Statistic title="Revenue" prefix="$" value={data?.Revenue?._sum?.amount?.toFixed(2) || 0} />
            </Col>

          </Row>
        </Card>

{/* ---------------Products On Hold------------------------ */}
        <Card className="site-statistic-demo-card">

          <Row gutter={16}>
            <Col span={0.1}>
              <Badge size="large" status="warning" />
            </Col>
            <Col span={15.9}>
              <Statistic title="Slabs on hold" value={data?.slabs?._count?.hold || 0} />
            </Col>

          </Row>
      </Card> 

       {/* ---------------Order Data------------------------ */}
      
       <Card className="site-statistic-demo-card" style={{ width: "50%" }}>
        <>
          <Row justify='space-around'>
          <Statistic
              title="Expected Revenue"
              value={data?.orders?._sum?.totalPrice || 0}
              precision={2}
              valueStyle={{ fontWeight:"bold"}}
              prefix="$"
              className='dashboard_statistic'
          />
          <Statistic
            title="Done"
            value={data?.Revenue?._sum?.amount || 0}
            precision={2}
            valueStyle={{ color: '#3f8600' }}
            prefix={<CheckCircleFilled />}
            suffix="$"
            className='dashboard_statistic'
          />
           <Statistic
            title="Remaining"
            value={data?.remainingRevenue  || 0}
            precision={2}
            valueStyle={{ color: '#cf1322' }}
            prefix={<ExclamationCircleFilled />}
            suffix="$"
            className='dashboard_statistic'
            />
          </Row>
          <Progress size='small' percent={data?.percent?.toFixed(2)} width={40} />
          </>
      </Card>
   
      </Row>


  
  );
};
export default Stats