import { UploadOutlined } from "@ant-design/icons";
import { ProForm, ModalForm, ProFormText } from "@ant-design/pro-components";
import ProTable from "@ant-design/pro-table";
import ImageUpload from "../../components/Common/ImageUpload";
import { Button, message } from "antd";
import { getUser } from "../../helpers";
import { useEffect, useRef, useState } from "react";
import marbleApi from "../../services/http/marbleApi";
import { stubTrue } from "lodash";
import "./style.less";

const NewArrival = () => {
  const actionRef = useRef();
  const [currentRow, setCurrentRow] = useState();
  const [urlll, setUrl] = useState();
  const [form] = ProForm.useForm();


  //fetching denied material
  const listMatarials = async (params, options) => {
    if (getUser().role === 'VENDOR') params.CardCode = getUser().CardCode;
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.newArrivals.list,
        {
          params,
          options,
        }
      );
        if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  // useEffect(() => {
  //   actionRef.current.reload()
  // }, [urlll]); 


    const columns = [
      {
        title: "Receipt No.",
        dataIndex: "DocNum",
        valueType: "textarea",
        sorter: true,
      },
      {
        title: "Country",
        dataIndex: "country",
        valueType: "textarea",
      },
      {
        title: "ETA Bodega Estimata",
        dataIndex: "arrivalDate",
        valueType: "textarea",
        sorter: true,
      },
      {
        title: "SKU",
        dataIndex: "sku",
        valueType: "textarea",
      },
      {
        title: "Products",
        dataIndex: "name",
        valueType: "textarea",
      },
      {
        title: "Medid Suma Cantidad",
        dataIndex: "quantity",
        valueType: "textarea",
        sorter: true,
      },
      {
        title: "Images",
        dataIndex: "images",
        search: false,
        hideInForm: true,
        render: (dom, entity) => (
          <ImageUpload
            callback={setUrl}
            limit={20}
            images={
              entity.images?.map((image) => ({
                uid: image.id,
                prefix: image.prefix,
                name: image.name,
                status: "done",
                url: image.url,
                uploader: image?.User?.name,
                source: image?.source,
              })) || []
            }
            slug="newArrivals"
            newArrivalId={entity.id}
          />
        ),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        sorter: true,
      },
    ];
  return (
    <>
      <ProTable
        headerTitle="New Arrivals"
        actionRef={actionRef}
        rowClassName={()=> getUser().role === "STONECLUB" && "uneditable-row" }
        rowKey="id"
        search={{
          labelWidth: 120,
        }}
        options={{
          fullScreen: false,
          reload: true,
          density: false,
          setting: false,
        }}
        request={listMatarials}
        columns={columns}
      />
    </>
  );
};

export default NewArrival;
