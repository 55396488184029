import { SelectOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ProForm,
} from "@ant-design/pro-components";
import ProTable from "@ant-design/pro-table";
import { Row, Modal, DatePicker, Input, Divider, Alert, Button, message, Tag, Tooltip, Popconfirm } from "antd";
import { useRef, useState, useEffect } from "react";
import marbleApi from "../../services/http/marbleApi";
import AddClient from "../order/new/AddClient";

const Client = () => {
  const [createModalVisible, handleModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updated, setupdated] = useState(null);
  const actionRef = useRef();
  const [currentRow, setCurrentRow] = useState();
  const [form] = ProForm.useForm();
  const [mode, setMode] = useState("create");

  useEffect(() => {
    actionRef.current.reload();
  }, [updated]);


  const handleUpdate = async (fields, id) => {
    const hide = message.loading("updating client");

    try {
      await marbleApi.http.post(marbleApi.urls.client.update(id), {
        data: fields,
      });
      hide();
      message.success("Contact updated successfully");
      actionRef.current.reload();
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };

  const listClient = async (params, options) => {
    params.status_enum = "ACTIVE";
    try {
      const response = await marbleApi.http.post(marbleApi.urls.client.list, {
        params,
        options,
      });
      if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };


  const archiveClient = async (id) => {
    await handleUpdate({ status: "INACTIVE" }, id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      tip: "Name",
      render: (dom, entity) => {
        return (
          <Button onClick={() => {
            setIsModalVisible(true)
            setCurrentRow(entity)
          }
          }
            type="link">
            <strong>{dom}</strong>
          </Button>
        );
      },
    },

    {
      title: "Group",
      dataIndex: "group",
      valueType: "textarea",
    },
    {
      title: "Sales Person",
      dataIndex: "sales_person",
      valueType: "textarea",
      render: (dom, entity) => {
        return (
          <Tag color="default" className="salesreptag">
            {dom}
          </Tag>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      valueType: "textarea",
    },
    {
      title: "ℹ Contact Info",
      dataIndex: "contact_person",
      valueType: "textarea",
      search: false,
      render: (dom, entity) => {
        return (
          <Alert
            showIcon={false}
            message={
              <div>
                {[
                  entity.contact_person,
                  entity.phone,
                  entity.mobile,
                  entity.email1,
                ].join("  ")}
              </div>
            }
            banner
          />
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      valueType: "textarea",
    },
    {
      title: "Status",
      dataIndex: "status",
      hideInForm: true,
      valueEnum: {
        ACTIVE: {
          text: "Active",
          status: "Success",
        },
        INACTIVE: {
          text: "Inactive",
          status: "Error",
        },
      },
    },

    {
      title: "",
      dataIndex: "option",
      valueType: "option",
      render: (_, record) => [
        <Tooltip
          title="Edit Contact"
          onClick={async () => {
            await setCurrentRow(record);
            await setMode("edit");
            handleModalVisible(true);
          }}
        >
          <Button type="link">
            <EditOutlined />
          </Button>
        </Tooltip>,
        <Popconfirm
          title="Are you sure you want to archive the client?"
          onConfirm={async () => await archiveClient(record.id)}
        >
          <Button type="link" danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>,
      ],
    },
  ];
  return (
    <>
      <ProTable
        headerTitle="Contact"
        actionRef={actionRef}
        rowKey="key"
        search={{
          labelWidth: 120,
        }}
        options={{
          fullScreen: false,
          reload: true,
          density: false,
          setting: false,
        }}
        toolBarRender={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              setMode("add");
              setCurrentRow({});
              handleModalVisible(true);
            }}
          >
            <PlusOutlined /> New Contact
          </Button>,
        ]}
        request={listClient}
        columns={columns}
      />

      <AddClient
        mode={mode}
        currentRow={mode === "edit" ? currentRow : null}
        handleModalVisible={handleModalVisible}
        createModalVisible={createModalVisible}
        setupdated={setupdated}
      />

      <Modal title={`${currentRow?.name} Details `} visible={isModalVisible} footer={null} onCancel={() => { setIsModalVisible(false) }}>

        <h3>Personal Details</h3>
        <h5>Email:</h5>
        <p>{currentRow?.email}</p>
        <h5>Group:</h5>
        <p>{currentRow?.group}</p>
        <h5>Mobile:</h5>
        <p>{currentRow?.mobile}</p>
        <h5>Phone:</h5>
        <p>{currentRow?.phone}</p>
        <h5>Billing Address:</h5>
        <p>{currentRow?.billing_address_text}</p>
        <h5>Shipping Address:</h5>
        <p>{currentRow?.shipping_address_text}</p>
        <h3>Other Details</h3>
        <h5>Status:</h5>
        <p>{currentRow?.status}</p>
        <h5>Payment Term:</h5>
        <p>{currentRow?.payment_terms}</p>
        <h5>Sales Person Assigned:</h5>
        <p>{currentRow?.sales_person}</p>
        <h5>Resell Due Date:</h5>
        <p>{currentRow?.resellDueDate}</p>
        <h5>Resell Validity:</h5>
        <p>{currentRow?.resellValid}</p>




      </Modal>
    </>
  );
};

export default Client;
