export function paymentStatus(order) {
  if (!order) return 0;
  let paid = 0;
  let price = order?.totalPrice
  if (price < 1) {
    price = 1
  }
  order.payments.forEach((payment) => {
    if (payment.status === "successful") {
      paid += payment.amount;
    }
  });
  return ((100 * paid) / price).toFixed(2);
}

export function paymentRemaining(order) {
  if (!order) return 0;
  let paid = 0;
  order.payments.forEach((payment) => {
    if (payment.status === "successful") {
      paid += payment.amount;
    }
  });
  return order.totalPrice - paid;
}

export function zohoAccountCheck(order) {
  let zohoAmount = order?.zohoAmount
  if (!order?.zohoAmount) {
    zohoAmount = order?.totalPrice || 0
  }
  let amount = (zohoAmount - order?.totalPrice).toFixed(2)
  if (amount == 0) {
    amount = ""
  }
  if (amount > 0) {
    amount = `+ $ ${amount}`
  }
  if (amount < 0) {
    amount = `- $ ${Math.abs(amount)}`
  }
  return amount
}

//calculating invoice
export function invoiceCheck(order) {
  if (!order) return 0;
  let paymentDone = 0;
  let invoiceDone = 0;

  if (order?.payments.length > 0) {
    const amount = order?.payments.filter(
      (payments) => payments.status === "successful"
    );
    if (amount?.length>0) {
      paymentDone = amount.map((i) => i.amount).reduce((a, b) => a + b);
    // console.log(paymentDone,"paymenttttt doneeeeee")
    }
    
  }

  if (order?.Invoice.length > 0) {
    const amount = order?.Invoice.filter(
      (Invoice) => Invoice.status === "valid"
    );
    if (amount.length > 0) {
      invoiceDone = amount.map((i) => i.total).reduce((a, b) => a + b);
    } else {
      invoiceDone = 0;
    }
  }
  return (paymentDone - invoiceDone).toFixed(2);
}

//calculating tax
export function checkingTAX(user, client) {
  let tax =0;
  if (client?.resellValid === true) {
    tax = 0
  }
  else {
    if (user.location === "Van Nuys") {
      tax = 9.5

    }
    if (user.location === "Anaheim") {
      tax = 7.75

    }
  }
  return tax
}


//calculating finalprice
export function getFinalPrice(entity) {
  let finalPrice = 0;
  if (entity.Slab?.size) {
    if (entity.discountType === "percent") {
      finalPrice = (
        (entity.price.price - (entity.price.price * entity.discount) / 100) *
        entity.Slab.size
      ).toFixed(2);
    } else {
      finalPrice = (
        (entity.price.price - entity.discount) *
        entity.Slab.size
      ).toFixed(2);
    }
  } else {
    if (entity.discountType === "percent") {
      finalPrice = (
        (entity.price.price - (entity.price.price * entity.discount) / 100) *
        entity.Slab?.existing
      ).toFixed(2);
    }
    else {
      finalPrice = (
        (entity.price.price - entity.discount) * entity.Slab.existing
      ).toFixed(2);
    }
  }

  return parseFloat(finalPrice);
};




export function priceforcreate(entity) {
  let finalPrice = 0;
  if (entity.size) {
    if (entity.discountType === "percent") {
      finalPrice = (
        (entity.price.price - (entity.price.price * entity.discount) / 100) *
        entity.size
      ).toFixed(2);
    } else {
      finalPrice = (
        (entity.price.price - entity.discount) *
        entity.size
      ).toFixed(2);
    }
  } else {
    if (entity.discountType === "percent") {
      finalPrice = (
        (entity.price.price - (entity.price.price * entity.discount) / 100) *
        entity.existing
      ).toFixed(2);
    }
    else {
      finalPrice = (
        (entity.price.price - entity.discount) * entity.existing
      ).toFixed(2);
    }
  }

  return parseFloat(finalPrice);
 }

