import { message } from "antd";
import marbleApi from "../services/http/marbleApi";
import { Navigate } from "react-router-dom";

export function waitTime(time = 100) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}

export function saveToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function updateLocalStorage(key, data) {
  let item = getFromLocalStorage(key);
  item = { ...item, ...data };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function getUser() {
  return getFromLocalStorage("user");
}

export async function updateCart(cart) {
  const user = getFromLocalStorage("user");
  if (!user) return;
  try {
    await marbleApi.http.post(marbleApi.urls.user.update(user?.id), {
      data: { cart },
    });
    return true;
  } catch (error) {
    message.error("Failed to update cart");
    message.error(error.message);
    return false;
  }
}

export function roleText(role) {
  switch (role) {
    case "ADMIN":
      return "🧑‍💼 Admin";
      break;

    case "SALES":
      return "💸 Sales";
      break;

    case "WAREHOUSE":
      return "📦 Warehouse";
      break;

    default:
      break;
  }
}

export function jsonToText(json) {
  let result = "";
  for (const key in json) {
    result += " " + json[key];
  }
  return result;
}

export function updateEnumKeys(enumKeys, obj) {
  for (const enumKey of enumKeys) {
    if (enumKey in obj && obj[enumKey]) {
      delete Object.assign(obj, { [`${enumKey}_enum`]: obj[enumKey] })[enumKey];
    }
  }
  return obj;
}

export async function refreshToken() {
  const user = getUser();
  if (!user) return <Navigate to="/login" />;

  try {
    const response = await marbleApi.http.post(
      marbleApi.urls.auth.refreshToken,
      { refreshToken: user.refreshToken }
    );

    user.accessToken = response.accessToken;
    user.refreshToken = response.refreshToken;
    saveToLocalStorage("user", user);
    return true;
  } catch (error) {
    localStorage.clear();
    <Navigate to="/login" />;
  }
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
