import React from 'react'
import { ProFormSelect, ModalForm, ProFormText } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import {Skeleton ,Empty , message, Col, Alert, Button, Row, Modal,TreeSelect } from "antd";
import { useState,useEffect } from "react";
import marbleApi from "../../services/http/marbleApi";
import { debounce } from "../../helpers";

function AddVendor({actionRef}) {
    const [previewVisible, setPreviewVisible] = useState(false);

      // searching lead convertLead
  const searchVendor = async (search) => {
    if (search.keyWords) {
      try {
        const response = await marbleApi.http.post(marbleApi.urls.sap.searchVendor, {
          name: search.keyWords,
        });
        if (response) {
          console.log(response)
          return response.map((item) => {
            return {
              value: item.CardCode,
              label: item.CardName,
            };
          });
        }
      } catch (error) {
        message.error(error?.message);
        return [];
      }
    }
    };
    
    const handleAdd = async (fields) => {
        const hide = message.loading("adding user");
        fields.CardCode = fields.CardCode.value
        fields.role = "VENDOR"
        try {
          await marbleApi.http.post(marbleApi.urls.auth.register, fields);
          hide();
          message.success(
            "Vendor Added successfully. He/She will receive an account setup email shortly."
          );
          return true;
        } catch (error) {
          hide();
          message.error(error.message);
          return false;
        }
      };

    return (
      <>
        <Button
          type="primary"
          key="primary"
          onClick={() => {
            setPreviewVisible(true);
          }}
        >
          <PlusOutlined /> Add Vendor
        </Button>

        <ModalForm
          title="Add Vendor"
          width="400px"
          visible={previewVisible}
          onVisibleChange={setPreviewVisible}
          onFinish={async (value) => {
              const success = await handleAdd(value);

              if (success) {
                setPreviewVisible(false);

                if (actionRef.current) {
                  actionRef.current.reload();
                }
              }
          }}
        >
          <ProFormSelect
            showSearch
            name="CardCode"
            label="Vendor's name"
            fieldProps={{
              labelInValue: true,
            }}
            debounceTime={500}
            request={searchVendor}
            onChange={(selected) => {
              console.log(selected);
              // setID(selected.value);
            }}
            rules={[
              {
                required: true,
                message: "Please select Vendor",
              },
            ]}
          />
          <ProFormText
            rules={[
              {
                required: true,
                message: "Email",
                type: "email",
              },
            ]}
            width="md"
            label="Email"
            name="email"
            placeholder={"Enter User Email"}
          />
          <ProFormSelect
            width="sm"
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
            options={[
              {
                label: "Van Nuys",
                value: "Van Nuys",
              },
              {
                label: "Anaheim",
                value: "Anaheim",
              },
            ]}
          />
        </ModalForm>
      </>
    );
}

export default AddVendor