import React, { useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Tooltip,message,Button } from "antd";
import marbleApi from "../../services/http/marbleApi";
import "./index.less";

export default function ZohoSync() {
  const [syncing, setSyncing] = useState("primary");
  const [buttonText, setButtonText] = useState("Zoho Sync");

// Sync zoho
    const syncZoho = async () => {
      setSyncing("secondary")
      setButtonText("Syncing")
    try {
      const response = await marbleApi.http.get(marbleApi.urls.zoho.sync);
        if (response) {
            if (response === 'error') {
                message.error('Try again');
            }
            else {
                message.success(response);
            }
          setSyncing("primary")
          setButtonText("Zoho Sync")
        }
    } catch (error) {
      setSyncing("primary")
      setButtonText("Zoho Sync")
      message.error(error?.message);
    }
   }
  return (
    <Tooltip className="sync_zoho" title={"Sync Zoho"}>
      <Button type={syncing} onClick={syncZoho}>{buttonText}</Button>
      {/* <SyncOutlined style={{color: syncing? '#fff' : '#2F54EB'}} onClick={syncZoho} /> */}
    </Tooltip>
  );
}
