import ProLayout from "@ant-design/pro-layout";
import { useRef, useEffect, useState } from "react";
import Footer from "./components/Layout/Footer";
import { useNavigate } from "react-router-dom";
import "./App.less";
import HeaderRightContent from "./components/HeaderRightContent";
import routes from "./components/routes";
import { ConfigProvider, message } from "antd";
import enUSIntl from "antd/lib/locale/en_US";
import marbleApi from "./services/http/marbleApi";
import { getUser, updateLocalStorage } from "./helpers";

function App(props) {
  const actionRef = useRef();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    async function syncCart() {
      try {
        const user = getUser();
        if (!user) return;
        const response = await marbleApi.http.get(
          marbleApi.urls.user.get(user.id)
        );
        if (response) {
          updateLocalStorage("user", response);
        }
      } catch (error) {
        message.error(error?.message);
        // navigate("/login");
      }
    }
    syncCart();
  }, []);

  return (
    <>
      <ConfigProvider locale={enUSIntl}>
        <ProLayout
          style={{}}
          location={{
            pathname: "/",
          }}
          actionRef={actionRef}
          logo={screenWidth >770? <img alt="logo" className="logo" src="/logo_white.svg" />: false}
          // logo={<img alt="logo" className="logo" src="/logo_white.svg" />}
          title="Marble Unlimited"
          headerTheme="dark"
          navTheme="dark"
          primaryColor="#2F54EB"
          layout="top"
          loading={false}
          footerRender={() => <Footer />}
          rightContentRender={() => <HeaderRightContent />}
          contentWidth="Fluid"
          route={routes()}
          locale={"en-US"}
          breakpoint={"xl"}
          menuItemRender={(menuItemProps) => {
            return (
              <div onClick={() => navigate(menuItemProps.path)}>
                {menuItemProps.icon} {menuItemProps.name}
              </div>
            );
          }}
          subMenuItemRender={(subMenuProps) => {
            return (
              <div onClick={() => navigate(subMenuProps.path)}>
                {subMenuProps.icon} {subMenuProps.name}
              </div>
            );
          }}
        >
          <div style={{ minHeight: "80vh" }}>{props.children}</div>
        </ProLayout>
      </ConfigProvider>
    </>
  );
}

export default App;
