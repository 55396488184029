import {
  CheckCircleFilled,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  ModalForm,
  ProFormSelect,
  ProFormText,
} from "@ant-design/pro-components";
import ProTable from "@ant-design/pro-table";
import { Button, message, Popconfirm, Space, Tooltip } from "antd";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../../components/Common/UserAvatar";
import { roleText, updateEnumKeys } from "../../helpers";
import marbleApi from "../../services/http/marbleApi";
import AddVendor from "./AddVendor"

const handleAdd = async (fields) => {
  const hide = message.loading("adding user");
  try {
    await marbleApi.http.post(marbleApi.urls.auth.register, fields);
    hide();
    message.success(
      "User Added successfully. He/She will receive an account setup email shortly."
    );
    return true;
  } catch (error) {
    hide();
    message.error(error.message);
    return false;
  }
};

//sending registration mail
const sendEmail = async (fields) => {
  const hide = message.loading("Sending email");
  try {
    await marbleApi.http.post(marbleApi.urls.auth.registrationEmail, {
      userId: fields,
    });
    hide();
    message.success("Email Sent");
    return true;
  } catch (error) {
    hide();
    message.error(error.message);
    return false;
  }
};

const Users = () => {
  const [createModalVisible, handleModalVisible] = useState(false);

  const actionRef = useRef();
  const [currentRow, setCurrentRow] = useState();
  const [selectedRowsState, setSelectedRows] = useState([]);

  const listUser = async (params, options) => {
    try {
      const response = await marbleApi.http.post(marbleApi.urls.user.list, {
        params: updateEnumKeys(["role"], params),
        options,
      });
      if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      tip: "Name",
      render: (dom, entity) => {
        return (
          <Link key={1} to={`/account/center/${entity.id}`}>
            <Space>
              <UserAvatar user={entity} size="44" round="10px" />
              <strong>{dom}</strong>
            </Space>
          </Link>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      valueType: "textarea",
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: true,
      hideInForm: true,
      renderText: (val) => roleText(val),
    },
    {
      title: "Location",
      dataIndex: "location",
      valueType: "textarea",
    },
    {
      title: "No. of Quotes",
      dataIndex: "quotes",
      sorter: true,
      hideInForm: true,
      renderText: (val) => `${val}`,
    },
    {
      title: "No. of Sales",
      dataIndex: "sales",
      sorter: true,
      hideInForm: true,
      renderText: (val) => `${val}`,
    },
    {
      title: "Custom Email",
      dataIndex: "custom_smtp",
      sorter: true,
      hideInForm: true,
      render: (dom, entity) => {
        const custom_smtp_ui = dom ? (
          <CheckCircleFilled style={{ color: "green" }} />
        ) : (
          "No"
        );
        return custom_smtp_ui;
      },
    },
    {
      title: "Account Status",
      dataIndex: "status",
      hideInForm: true,
      valueEnum: {
        ACTIVE: {
          text: "Active",
          status: "Success",
        },
        INACTIVE: {
          text: "Inactive",
          status: "Error",
        },
      },
    },
    {
      title: "Last Active",
      sorter: true,
      dataIndex: "updatedAt",
      valueType: "dateTime",
    },
    {
      title: "",
      dataIndex: "option",
      valueType: "option",
      render: (_, record) => [
        <Link key={1} to={`/account/settings/${record.id}/base`}>
          Edit{" "}
        </Link>,
        <Link key={1} to={`/account/center/${record.id}`}>
          Logs{" "}
        </Link>,
        record.name === null && record.status === "INACTIVE" && (
          <Tooltip title="Resend account setup email" placement="bottom">
            {" "}
            <Popconfirm
              title="Do you want to resend account setup email?"
              onConfirm={() => {
                sendEmail(record.id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <MailOutlined style={{ color: "#2F54EB" }} />
            </Popconfirm>
          </Tooltip>
        ),
      ],
    },
  ];
  return (
    <>
      <ProTable
        headerTitle="Users"
        actionRef={actionRef}
        rowKey="key"
        search={{
          labelWidth: 120,
          searchText: "Filter",
        }}
        options={{
          fullScreen: false,
          reload: true,
          density: false,
          setting: false,
        }}
        toolBarRender={() => [
          <AddVendor actionRef={ actionRef } />,
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              handleModalVisible(true);
            }}
          >
            <PlusOutlined /> New User
          </Button>,
        ]}
        request={listUser}
        columns={columns}
        /* rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }} */
      />

      <ModalForm
        title="🙂 Create New User"
        width="400px"
        visible={createModalVisible}
        onVisibleChange={handleModalVisible}
        onFinish={async (value) => {
          const success = await handleAdd(value);

          if (success) {
            handleModalVisible(false);

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
      >
        <ProFormText
          rules={[
            {
              required: true,
              message: "Email",
              type: "email",
            },
          ]}
          width="md"
          label="Email"
          name="email"
          placeholder={"Enter User Email"}
        />
        <ProFormSelect
          width="sm"
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[
            {
              label: "Admin",
              value: "ADMIN",
            },
            {
              label: "Sales",
              value: "SALES",
            },
            {
              label: "Warehouse",
              value: "WAREHOUSE",
            },
            {
              label: "Accounting",
              value: "ACCOUNTING",
            },
            {
              label: "stoneclub",
              value: "STONECLUB",
            },
          ]}
        />

        <ProFormSelect
          width="sm"
          name="location"
          label="Location"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[
            {
              label: "Van Nuys",
              value: "Van Nuys",
            },
            {
              label: "Anaheim",
              value: "Anaheim",
            },
          ]}
        />
      </ModalForm>
    </>
  );
};

export default Users;
