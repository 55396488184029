/* eslint-disable no-lone-blocks */
import {
  BlockOutlined,
  FileTextOutlined,
  HomeOutlined,
  IdcardOutlined,
  MoreOutlined,
  PictureOutlined,
  StopOutlined,
  TeamOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { getFromLocalStorage } from "../helpers";

export default function routes() {
  const user = getFromLocalStorage("user");
  const role = user?.role;
  const email = user?.email;

  const routes_ = {
    path: "/",
    routes: [],
    location: {
      pathname: "/",
    },
  };

  const routeList = [
    //Dashboard

    {
      path: "/home",
      name: "Home",
      icon: <HomeOutlined />,
      component: "./dashboard",
    },

    //Quotes

    {
      name: "Orders",
      icon: <FileTextOutlined />,
      path: "/orders",
      component: "./orders",
    },

    //Products

    {
      name: "Products",
      icon: <BlockOutlined />,
      path: "/products",
      component: "./products",
    },

    //Images

    {
      name: "Images",
      icon: <PictureOutlined />,
      path: "/images",
      component: "./images",
    },

    //Users

    {
      name: "Users",
      icon: <TeamOutlined />,
      path: "/users",
      component: "./users",
    },
    {
      name: "Prices",
      icon: <DollarOutlined />,
      path: "/price",
    },
    {
      name: "New Arrivals",
      icon: <IdcardOutlined />,
      path: "/new-arrivals",
    },
    {
      name: "More",
      icon: <MoreOutlined />,
      routes: [
        {
          name: "Denied Materials",
          icon: <StopOutlined />,
          path: "/denied-materials",
        },
        {
          name: "Contacts",
          icon: <IdcardOutlined />,
          path: "/clients",
        },
      ],
    },
  ];

  //add home

  switch (role) {
    case "ADMIN":
      routes_.routes.push(
        routeList[0],
        routeList[1],
        routeList[2],
        routeList[3],
        routeList[4],
        routeList[6],
        routeList[7]
      );
      break;
    case "SALES":
      routes_.routes.push(
        routeList[0],
        routeList[1],
        routeList[2],
        routeList[6],
        routeList[7],
      );
      break;

    case "WAREHOUSE":
      routes_.routes.push(routeList[0], routeList[2], routeList[6]);
      break;
      case "ACCOUNTING":
        routes_.routes.push(routeList[1], routeList[7]);
      break;
    case "STONECLUB":
      routes_.routes.push(routeList[2], routeList[6]);
      break;
    case "VENDOR":
      routes_.routes.push(routeList[6]);
      break;

    default:
      break;
  }
  if (email === 'jmijares@marbleunlimitedinc.com') {
    routes_.routes.push(routeList[6]);
  }

  return routes_;
}
