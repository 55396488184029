import { getFromLocalStorage } from "../../helpers";
import { DeleteOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { useState, useContext } from "react";
import styles from "./index.less";
import NoticeIcon from "./NoticeIcon";
import appContext from "../../context/app-context";
import { useNavigate } from "react-router-dom";

const CartView = () => {
  const context = useContext(appContext);
  const navigate = useNavigate();

  return (
    <NoticeIcon
      className="action"
      count={context?.cart?.length}
      bell={<ShoppingCartOutlined style={{ fontSize: 28 }} />}
      loading={false}
      clearText="Clear"
      viewMoreText="Create New Order"
      onViewMore={() => navigate("/orders/create-new-order")}
      clearClose
      onClear={() => {
        context.clearCart();
      }}
    >
      <NoticeIcon.Tab
        tabKey="cart"
        title="Cart"
        emptyText="All Clear !"
        count={context?.cart?.length}
        list={context?.cart?.map((item) => ({
          key:item.id,
          id: item.id,
          title: <h4 style={{ color: "#2F54EB" }}>{item.name}</h4>,
          description: (
            <>
              <div style={{ margin: 5 }}>
                <p>
                  <strong>Slab : {item.slabId}</strong> &nbsp;/ &nbsp;
                  <strong>{item.existing} SQF</strong>
                  <br />
                  SKU : {item.sku} /
                </p>
              </div>
            </>
          ),
          type: "message",
          clickClose: true,
          extra: [
            <Button
              key={`deletebtn_${item.id}`}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={context.removeProductFromCart.bind(this, item.id)}
            />,
          ],
        }))}
        showViewMore
      />
      <NoticeIcon.Tab
        tabKey="saved"
        count={0}
        list={
          [
            /*
          {
            id: '000000008',
            title: <h4 style={{ color: '#2F54EB' }}>Some thing Something Important</h4>,
            description: (
              <>
                <div style={{ margin: 5 }}>
                  <p>Some important Detail that you can't miss.</p>
                </div>
              </>
            ),
            type: 'message',
            clickClose: true,
            extra: [],
          }, */
          ]
        }
        title="Saved"
        emptyText="All Clear !"
      />
    </NoticeIcon>
  );
};

export default CartView;
