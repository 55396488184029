import React from 'react';
import Avatar from 'react-avatar';

export default function UserAvatar(props) {
  const { user, size, round, style } = props;

  const avatarStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: typeof round === 'boolean' && round ? '50%' : round,
    objectFit: 'cover',
    ...style,
  };

  return user?.avatar ? (
    <img src={user.avatar} style={avatarStyle}></img>
  ) : (
    <Avatar
      color={'#2F54EB'}
      email={user?.email}
      name={user?.name}
      src={user?.avatar}
      size={size}
      round={round}
      textSizeRatio={1}
      style={style}
    />
  );
}
