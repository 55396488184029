import { useRef, useState, useEffect } from "react";
import { ModalForm, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { UserOutlined } from "@ant-design/icons";
import {message} from "antd";
import marbleApi from "../../../services/http/marbleApi";

export default function Owner(props) {
  const { modalVisible2, setModalVisible2, order, syncOrder } = props;
  const [salesReps, setSalesReps] = useState([]);
  const [users, setUsers] = useState([]);

  //get sales person
  useEffect(() => {
    const getSalesReps = async (params, options) => {
      try {
        const response = await marbleApi.http.post(marbleApi.urls.user.list, {
          params: { current: 1, pageSize: 1000 },
          options,
        });
        if (response) {
          setSalesReps(response.data);
          setUsers(response?.data?.filter((i) => i.zohoID !== null));
          return response;
        }
      } catch (error) {
        message.error(error?.message);
        return [];
      }
    };
    getSalesReps();
  }, []);

  function handleChange(value) {
    if (value === "zoho") {
      const filteredUsers = salesReps.filter((i) => i.zohoID !== null);
      setUsers(filteredUsers);
    } else {
      setUsers(salesReps);
    }
  }

  // updating order owner
  const handleUpdate = async (fields) => {
    console.log(fields, "fields");
    let userId = fields.user;
    const hide = message.loading("Updating");
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.order.update(order.id),
        {
          data: { userId },
        }
      );
      hide();
      message.success("Updated successsfully");
      await syncOrder();
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };

  return (
    <>
      <ModalForm
        title={
          <span>
            <UserOutlined /> Assign new owner
          </span>
        }
        width="500px"
        visible={modalVisible2}
        onVisibleChange={setModalVisible2}
        onFinish={async (value) => {
          const success = await handleUpdate(value);
          if (success) {
            setModalVisible2(false);
          }
        }}
      >
        <ProFormSelect
          //   width="sm"
          name="status"
          label="Search Category"
          initialValue="zoho"
          onChange={(value) => handleChange(value)}
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[
            {
              label: "All users",
              value: "all",
            },
            {
              label: "Users with zoho accounts",
              value: "zoho",
            },
          ]}
        />
        <ProFormSelect
          name="user"
          label="Select User"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={users.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })}
        />
      </ModalForm>
    </>
  );
}
