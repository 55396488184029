import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  InputNumber,
  Select,
  Table,
  Modal,
  Form,
  message,
  Popconfirm,
} from "antd";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import marbleApi from "../../../services/http/marbleApi";
import { getUser } from "../../../helpers";

import styles from "../style.less";

const { Option } = Select;

function SpecialProducts(props) {
  const [modalVisible, setModalVisible] = useState(false);

  const [specialProducts, setSpecialProducts] = useState([]);
  const { callback, data, order, syncOrder } = props;
  const [editableKeys, setEditableRowKeys] = useState(null);

  useEffect(() => {
    if (callback) callback(specialProducts);
  }, [callback, specialProducts]);

  useEffect(() => {
    if (data) setSpecialProducts(data);
  }, [data]);

  const handleChange = (id, key, value) => {
    setSpecialProducts((slabs) => {
      const copy = [...slabs];
      const index = copy.findIndex((slab) => slab.id === id);
      copy[index][`${key}`] = value;

      if (["price", "discount", "size"].includes(key)) {
        copy[index].finalPrice = getFinalPrice(copy[index]);
      }
      return copy;
    });
  };

  const getFinalPrice = (entity) => {
    let finalPrice = 0;
    if (entity.discountType === "amount") {
      finalPrice = ((entity.price - entity.discount) * entity.size).toFixed(2);
    } else if (entity.discountType === "percent") {
      finalPrice = (
        (entity.price - (entity.price * entity.discount) / 100) *
        entity.size
      ).toFixed(2);
    } else {
      finalPrice = (entity.price * entity.size).toFixed(2);
    }
    return parseFloat(finalPrice);
  };

  // const updateOrder = async () => {
  //   const hide = message.loading("Updating");

  //   try {
  //     await marbleApi.http.post(marbleApi.urls.order.update(order?.id), {
  //       data: {
  //         specialProducts,
  //       },
  //     });
  //     hide();
  //     message.success("Updated successsfully");
  //     await syncOrder();
  //     return true;
  //   } catch (error) {
  //     hide();
  //     message.error(error.message);
  //     return false;
  //   }
  // };

  //Adding special Product
  const handleAdd = async (fields) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("Creating");
      if (order) {
        fields.orderid = order?.id;
      }
      fields.discount = 0;
      fields.finalPrice = getFinalPrice(fields);
      fields.discountType = "percent";
      try {
        const response = await marbleApi.http.post(
          marbleApi.urls.specialProducts.create,
          fields
        );
        hide();
        setModalVisible(false);
        message.success("Created successsfully");
        if (order) {
          await syncOrder();
        } else {
          setSpecialProducts([...specialProducts, response]);
        }

        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    }
  };

  //Deleting special product
  const handleDelete = async (id) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("Deleting");
      try {
        await marbleApi.http.delete(marbleApi.urls.specialProducts.delete(id));
        hide();
        setModalVisible(false);
        message.success("Deleted");
        if (order) {
          await syncOrder();
        } else {
          setSpecialProducts((slabs) => {
            const copy = [...slabs];
            const index = copy.findIndex((slab) => slab.id === id);
            copy.splice(index, 1);
            return copy;
          });
        }
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    }
  };

  //Editing special product
  const handleEdit = async (entity) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id && order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      const hide = message.loading("editing");
      try {
        await marbleApi.http.post(
          marbleApi.urls.specialProducts.update(entity.id),
          entity
        );
        hide();
        setModalVisible(false);
        message.success("edited successfully");
        await syncOrder();
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        return false;
      }
    };
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      tip: "Material Name",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <Input
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "name", e.target.value)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <Input
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "sku", e.target.value)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "Block No.",
      dataIndex: "block",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <Input
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "block", e.target.value)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "Slab No.",
      dataIndex: "slab",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <Input
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "slab", e.target.value)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <InputNumber
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "size", e)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "Height",
      dataIndex: "height",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <InputNumber
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "height", e)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "Width",
      dataIndex: "width",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <InputNumber
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "width", e)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },

    {
      title: "Price / sqf",
      dataIndex: "price",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <InputNumber
            className={styles.OrderProductInput}
            value={dom}
            onChange={(e) => handleChange(entity.id, "price", e)}
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },

    {
      title: "Discount / sqf",
      dataIndex: "discount",
      valueType: "textarea",
      render: (dom, entity) => {
        return editableKeys === entity.id ? (
          <InputNumber
            style={{ width: 140 }}
            bordered={false}
            value={dom}
            controls={false}
            onChange={(e) => handleChange(entity.id, "discount", e)}
            className="productDiscountInput"
            addonAfter={
              <Select
                defaultValue={entity.discountType || "percent"}
                style={{ width: 60 }}
                bordered={false}
                onChange={(e) => handleChange(entity.id, "discountType", e)}
              >
                <Option value="percent">%</Option>
                <Option value="amount">$</Option>
              </Select>
            }
          />
        ) : (
          <p>{dom}</p>
        );
      },
    },
    {
      title: "Final Price",
      dataIndex: "final",
      valueType: "textarea",
      render: (dom, entity) => {
        return <>{entity.finalPrice}</>;
      },
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      valueType: "option",
      render: (dom, entity) => {
        return order && !editableKeys ?
          (<>
            <Button
              onClick={() => {
                setEditableRowKeys(entity.id);
              }}
              key="edit"
              type="link"
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={async (e, id = entity.id) => {
                await handleDelete(id);
                await syncOrder();
              }}
              okText="Yes"
              cancelText="No"
              key="pop"
            >
              <Button
                type="text"
                key="delete"
                danger
              // onClick={() => {
              //   setSpecialProducts((slabs) => {
              //     const copy = [...slabs];
              //     const index = copy.findIndex((slab) => slab.id === entity.id);
              //     copy.splice(index, 1);
              //     return copy;
              //   });
              // }}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>)
         : editableKeys?
        //editableKeys &&
        (<>
          <Button
            onClick={() => {
              handleEdit(entity);
              //setEditableRowKeys(entity.id);
            }}
            key="save"
            type="link"
          >
            save
          </Button>
          <Button
            onClick={() => {
              setEditableRowKeys(null);
            }}
            key="cancel"
            type="link"
          >
            cancel
          </Button>
        </>):null
      }
    }

  ];

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // const onFinish = (values) => {
  //   values.Special_product.id = uuidv4();
  //   values.Special_product.discount = 0;
  //   values.Special_product.finalPrice = getFinalPrice(values.Special_product);
  //   values.Special_product.discountType = "percent";
  //   setSpecialProducts([...specialProducts, values.Special_product]);
  //   setModalVisible(false);
  // };

  return (
    <>
      <Table
        key="id"
        rowKey="id"
        pagination={false}
        loading={false}
        dataSource={specialProducts}
        columns={columns}
        title={() => {
          return (
            <>
              <Button
                type="link"
                key={2}
                style={{ float: "right", marginBottom: 20 }}
                onClick={() => setModalVisible(true)}
              >
                <PlusOutlined /> Add Special Products
              </Button>
            </>
          );
        }}
      />
      <Modal
        title="Add Special Product"
        centered
        maskClosable={false}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width="60%"
        style={{}}
      >
        <Form
          {...layout}
          name="nest-messages"
          onFinish={handleAdd}
          validateMessages={validateMessages}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="sku" label="SKU" rules={[]}>
            <Input />
          </Form.Item>
          <Form.Item name="block" label="Block" rules={[]}>
            <Input />
          </Form.Item>
          <Form.Item name="slab" label="Slab" rules={[]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price / SQF"
            rules={[{ type: "number", required: true }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="size"
            label="Size"
            rules={[{ type: "number", required: true }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item name="width" label="Width">
            <InputNumber />
          </Form.Item>

          <Form.Item name="height" label="Height">
            <InputNumber />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default SpecialProducts;
