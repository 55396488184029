import { CarryOutTwoTone, EnvironmentTwoTone } from "@ant-design/icons";
import { Button, Collapse, DatePicker, Form, Input } from "antd";
import { debounce } from "../../../helpers";
import { useState } from "react";

const { Panel } = Collapse;

function Address({ setDelivery, setPickup }) {
  // const [dil, setdel] = useState({ date: null, address: null });
  // console.log(dil,"diiillleeveerrrrryyy")

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = (values) => {
    console.log(values);
    // setdel({...dil, date:values.date, address: values.address })
    setDelivery(values);
    // setdel(values => {
    //   return { 
    //     ...values,
    //     address: values.address
    //   }
    // })
    
  };

  const onPickup = (values) => {
    console.log(values);
    setPickup(values);
  };

  return (
    <Collapse defaultActiveKey={[]} ghost>
      <Panel
        header={
          <span>
            <EnvironmentTwoTone /> Delivery Details
          </span>
        }
        key="1"
      >
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          // onValuesChange={debounce(onFinish)}

          validateMessages={validateMessages}
        >
          <Form.Item
            name="date"
            // name={["delivery", "date"]}
            label="Date"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            name="address"
            // name={["delivery", "address"]}
            label="Address"
            rules={[{ required: true }]}
            
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Panel>
      <Panel
        header={
          <span>
            <CarryOutTwoTone /> Pick-Up Details
          </span>
        }
        key="2"
      >
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onPickup}
          // onValuesChange={debounce(onPickup)}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="date"
            // name={["pickup", "date"]}
            label="Date"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            // name={["pickup", "address"]}
            name="address"
            label="Address"
            rules={[{ required: true }]}
           
            
          >
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item
            name="company"
            // name={["pickup", "company"]}
            label="Pickup Company"
            rules={[{ required: true }]}
            
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="person"
            // name={["pickup", "person"]}
            label="Contact Person"
            rules={[{ required: true }]}
            
          >
            <Input  />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Panel>
    </Collapse>
  );
}

export default Address;
