import React, { useState } from "react";
import { List, message,Button, Divider } from "antd";
import { ModalForm, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import marbleApi from "../../../../services/http/marbleApi";
import ZohoSync from "../../../../components/HeaderRightContent/ZohoSync";
import SapSync from "../../../../components/HeaderRightContent/SapSync";
import {getUser} from "../../../../helpers";

const SecurityView = (props) => {
  const { currentUser } = props;
  const [passwordModal, setPasswordModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);

  const handleUpdate = async (fields) => {
    const hide = message.loading("Updating");

    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.user.update(currentUser?.id),
        {
          data: fields,
        }
      );
      hide();
      message.success("Updated successsfully");
      return true;
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };

  const getData = () => [
    {
      title: "Account Password",
      description: <></>,
      actions: [
        <a onClick={() => setPasswordModal(true)} key="pass">
          Change Password
        </a>,
      ],
    },
    {
      title: "Account Status",
      description: `Current Status : ${currentUser?.status}`,
      actions: [
        <a onClick={() => setStatusModal(true)} key="status">
          Change Status
        </a>,
      ],
    },
  ];

  const data = getData();
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item actions={item.actions}>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      />

      <ModalForm
        title="🗝 Update Password"
        width="400px"
        visible={passwordModal}
        onVisibleChange={setPasswordModal}
        onFinish={async (value) => {
          const success = await handleUpdate({ password: value.password });
          if (success) {
            setPasswordModal(false);
            /* if (actionRef.current) {
              actionRef.current.reload();
            } */
          }
        }}
      >
        <ProFormText.Password
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
          width="md"
          label="Password"
          name="password"
          type
          placeholder={"Enter Password"}
        />
        <ProFormText.Password
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
          width="md"
          label="Confirm Password"
          name="confirm"
          placeholder={"Confirm Password"}
          hasFeedback
        />
      </ModalForm>
      <ModalForm
        title="⚠ Update Status"
        width="400px"
        visible={statusModal}
        onVisibleChange={setStatusModal}
        onFinish={async (value) => {
          const success = await handleUpdate(value);
          if (success) {
            setStatusModal(false);
            /*  
           if (actionRef.current) {
              actionRef.current.reload();
            } 
            */
          }
        }}
      >
        <ProFormSelect
          width="sm"
          name="status"
          label="Status"
          initialValue={currentUser?.status}
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
          options={[
            {
              label: "Active",
              value: "ACTIVE",
            },
            {
              label: "Inactive",
              value: "INACTIVE",
            },
          ]}
        />
      </ModalForm>
      {/* <Divider />
      {getUser().role !== "WAREHOUSE" && <ZohoSync />}
      { getUser().role !== "WAREHOUSE" && <SapSync user={getUser()} /> } */}
    </>
  );
};

export default SecurityView;
