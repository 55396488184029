import { PlusOutlined } from "@ant-design/icons";
import { ModalForm, ProFormDigit, ProFormMoney, ProForm, ProFormText,ProFormSelect } from "@ant-design/pro-components";
import ProTable from "@ant-design/pro-table";
import { Link, useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { useEffect, useRef, useState } from "react";
import marbleApi from "../../services/http/marbleApi";

const DeniedMatarial = () => {
  const [createModalVisible, handleModalVisible] = useState(false);

  const actionRef = useRef();
  const [currentRow, setCurrentRow] = useState();
  const [form] = ProForm.useForm();
  const [mode, setMode] = useState("create");
  const [id, setID] = useState("");

  useEffect(() => {
    form.setFieldsValue(currentRow);
  }, [form, currentRow]);

  // fetching orders
  const getOrders = async (search) => {
    const params = {
      current: 1,
      pageSize: 2000,
      description: search.keyWords,
    };
      try {
        const response = await marbleApi.http.post(marbleApi.urls.order.list, {
            params,
            options: {},
          });
        if (response) {
            return response.data.map((item) => {
              return {
                value: item?.id,
                label: `${item.orderID} - ${item.description}`,
              };
            });
        }
      } catch (error) {
        message.error(error?.message);
        return [];
      }
  };

  //adding denied material
  const handleAdd = async (fields) => {
    // fields.orderid = id;
    const hide = message.loading("adding products");
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.deniedMatarial.create,
        fields
      );
      if (response) {
        hide();
        message.success("Product added");
        actionRef.current.reload();
        return true;
      } else {
        hide();
        message.error("Product already exist");
        actionRef.current.reload();
        return false;
      }
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };

  //fetching denied material
  const listMatarials = async (params, options) => {
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.deniedMatarial.list,
        {
          params,
          options,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  // fetching product names
  const listProducts =async (search) => {
    const params = {
      current: 1,
      pageSize: 2000,
      name: search.keyWords,
    };
    try {
      const response = await marbleApi.http.post(marbleApi.urls.products.list, {
        params,
        options: {},
      });
      if (response) {
        const products = response.data.map((item) => {
          return {
            value: item.name,
            label: item.name,
          };
        });
        products.push({
          value: search.keyWords,
          label: search.keyWords,
        })
        return products
      }
    } catch (error) {
      message.error(error?.message);
      return [];
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      valueType: "textarea",
    },
    {
      title: "Notes",
      dataIndex: "description",
      valueType: "textarea",
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      valueType: "textarea",
    },
    {
      title: "Sqft",
      dataIndex: "size",
      valueType: "textarea",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
    },
  ];
  return (
    <>
      <ProTable
        headerTitle="Denied Products"
        actionRef={actionRef}
        rowKey="id"
        search={{
          labelWidth: 120,
        }}
        options={{
          fullScreen: false,
          reload: true,
          density: false,
          setting: false,
        }}
        toolBarRender={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              setMode("add");
              setCurrentRow({});
              handleModalVisible(true);
            }}
          >
            <PlusOutlined /> New Product
          </Button>,
        ]}
        request={listMatarials}
        columns={columns}
      />

      <ModalForm
        title="Add Denied Product"
        width="60vw"
        visible={createModalVisible}
        onVisibleChange={handleModalVisible}
        initialValues={currentRow}
        form={form}
        submitter={{
          searchConfig: {
            submitText: "Create",
          },
        }}
        onFinish={async (value) => {
          let success = null;
          if (mode === "add") {
            success = await handleAdd(value);
          }
          //   if (mode === "edit") {
          //     success = await handleUpdate(value, currentRow.id);
          //   }

          if (success) {
            handleModalVisible(false);

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
      >
        <ProFormSelect
          rules={[{ required: true }]}
          showSearch
          label="Product name"
          name="name"
          placeholder=""
          fieldProps={{
            style: {
              minWidth: 300,
            },
          }}
          debounceTime={500}
          request={listProducts}
        />
        <ProFormText
          label="Notes"
          name="description"
          placeholder=""
        />
        <ProFormMoney
          rules={[{required: true}]}
          label="Total (USD)"
          name="totalPrice"
          locale="en-US"
          min={0}
        />
        <ProFormDigit
          rules={[{required: true}]}
          min={1} label="Sqft" name="size" />
      </ModalForm>
    </>
  );
};

export default DeniedMatarial;
