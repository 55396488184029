import { Col, Row, Table, Card, Empty } from "antd";
import React, { useEffect, useState } from "react";
import { HistoryOutlined, ArrowUpOutlined } from "@ant-design/icons";
import "./index.css";

//--------------------------- Table Data-------------------------
const mydata = [];
// const onChange = (pagination, filters, sorter, extra) => {
//   console.log("params", pagination, filters, sorter, extra);
// };

//-------------------------------Table---------------------------------------

const QuoteTable = ({ data }) => {
  // const [mydata, setData] = useState(data || []);
  //--------------------------- table structure-----------------------------

  const columns = [
    {
      title: "Quote ID",
      dataIndex: "id",
      valueType: "textarea",
    },
    {
      title: "Contact",
      dataIndex: "Client",
      render: (dom, entity) => {
        return <span>{entity?.Order?.client?.name}</span>;
      },
    },
    {
      title: "Sent",
      dataIndex: "sent",
      render: (dom, entity) => {
        return <span>{dom === true ? "Yes" : "No"}</span>;
      },
    },
  ];

  const columnsForClient = [
    {
      title: "Name",
      dataIndex: "clientName",
      valueType: "textarea",
    },
    {
      title: "Total Orders",
      dataIndex: "orders",
      render: (dom, entity) => {
        return <span>{entity?._count?.id}</span>;
      },
    },
    {
      title: "Expected Revenue",
      dataIndex: "amount",
      render: (dom, entity) => {
        return <span>$ {entity?._sum?.totalPrice?.toFixed(2)}</span>;
      },
    },
  ];

  return (
    <>
      <Row>
        <Col className="dashboard" flex={1}>
          <Card
            className="card_margin"
            bordered={false}
            title={
              <>
                <HistoryOutlined /> {"Recent Quotes"}
              </>
            }
            style={{
              height: "100%",
            }}
          >
            {mydata ? (
              <Table
                columns={columns}
                dataSource={data?.quote}
                // onChange={onChange}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                // onChange={onChange}
              />
            )}
          </Card>
        </Col>
        <Col className="dashboard" flex={1}>
          <Card
            className="card_margin"
            bordered={false}
            title={
              <>
                <ArrowUpOutlined /> {"Top Contacts"}
              </>
            }
            style={{
              height: "100%",
            }}
          >
            {mydata ? (
              <Table
                columns={columnsForClient}
                dataSource={data?.clients}
                // onChange={onChange}
              />
            ) : (
              <Table
                columns={columnsForClient}
                dataSource={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                // onChange={onChange}
              />
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default QuoteTable;
