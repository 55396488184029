import React, { useState } from "react";
import ZohoSync from "../../../../components/HeaderRightContent/ZohoSync";
import SapSync from "../../../../components/HeaderRightContent/SapSync";
import {getUser} from "../../../../helpers";

const SyncView = (props) => {
  const { currentUser } = props;


  return (
    <>
          {getUser().role !== "WAREHOUSE" && <ZohoSync />}
          <br />
          <br />
      { getUser().role !== "WAREHOUSE" && <SapSync user={getUser()} /> }
    </>
  );
};

export default SyncView;
