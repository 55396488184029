import { SearchOutlined } from "@ant-design/icons";
import { ProFormText } from "@ant-design/pro-components";
import { Card, Alert, Button, message,Collapse  } from "antd";
import {  useState, useEffect } from "react";
import marbleApi from "../../../services/http/marbleApi";
import AddAccount from "./AddAccount";
const { Panel } = Collapse;



function LinkAccount(props) {
  const { setZohoAccount, email, client, name } = props;
  const [allAccounts, setAllAccounts] = useState([]);
  const [accountModalVisible, handleAccountModalVisible] = useState(false);
  const [domain, setDomain] = useState(null);
  const emailDomains = ["gmail.com","yahoo.com","hotmail.com"]
  const myDomain = email?.split("@")[1]
  const firstName = name?.split(" ")[0]
    
  useEffect(() => {
    if (emailDomains.includes(myDomain) || !email) {
        setDomain(firstName)
        searchAccount(firstName)
      }
    else {
        setDomain(myDomain)
        searchAccount(myDomain)
      }
  }, [email,name]);

  // search Account
  const searchAccount = async (domain) => {
      message.loading({
        content: "fetching...",
        key: "fetching",
        duration: 0,
      });
      try {
        const response = await marbleApi.http.post(
          marbleApi.urls.zoho.searchAccount,
          {
            data: domain,
          }
        );
        if (response?.length>0) {
          setAllAccounts(response);
          message.success({
            content: "Account found",
            key: "fetching",
          });
        } else {
          message.warning({
            content: "no account found!",
            key: "fetching",
          });
          setAllAccounts([]);
        }
      } catch (error) {
        message.error({
          content: error?.message,
          key: "fetching",
        });
        return [];
      }
    }

    // search Account
  const linkAccout = async (accountDetails) => {
    if (client) {
      // if (client.zohoOwnerId) {
        try {
          message.loading({
            content: "Linking Contact...",
            key: "link",
            duration: 0,
          });
         const response = await marbleApi.http.post(marbleApi.urls.client.update(client.id), {
            data: {
              zohoAccountId: accountDetails?.id,
              zohoOwnerId: accountDetails?.Owner.id,
              sales_person: accountDetails?.Owner.name
            },
         });
          if (response) {
            if (response !== true) {
              message.error({
                content: response,
                key: "link",
              });
            }
            else {
              message.success({
                content: "Contact linked successfully",
                key: "link",
              });
              setZohoAccount(accountDetails.id)
            }
          }
        } catch (error) {
          message.error({
            content: error.message,
            key: "link",
          });
          return false;
        }
      // }
      // else {
      //   message.error({
      //     content: `( ${ client?.sales_person } ) Salesperson/Owner of selected Contact is not on zoho`,
      //     key: "link",
      //   });
      // }
    }
    else {
      setZohoAccount(accountDetails.id)
    }
    };


  return (
    <>
      <Card>
        <ProFormText
          // width="md"
          name="search"
          value={domain}
          label="  Website / Account Name"
          onChange={(e) => {
            setDomain(e.target.value);
          }}
          addonAfter={
            <>
              <Button
                type="primary"
                style={{ width: 30 }}
                icon={<SearchOutlined />}
                block
                onClick={() => searchAccount(domain)}
              />{" "}
              <Button
                onClick={() => handleAccountModalVisible(true)}
                type="primary"
              >
                Add account
              </Button>
            </>
          }
        />
        <br></br>
        {allAccounts.length > 0 ?
          <>
          <b>Select Account</b>
          <Card style={{ height: '200px', overflowY: "scroll" }}>
          <Collapse accordion>
          {allAccounts?.map((item) => (
            <Panel
              header={
                  <>
                  <i>{item?.Account_Name}</i>
                  {" "}
                  
                  <Button
                    style={{ float: "right" }}
                    onClick={() => linkAccout(item)}
                    type="link"
                  >
                    link
                    </Button>
                    </>
              }
              key={item.id}
            >
              <div>
                Account Name - <b>{item?.Account_Name}</b> / Website -{" "}
                <b>{item?.Website || "NA"}</b> / Owner - <b>{item?.Owner?.name}</b>
                <br></br>
                Source -{" "}
                <b>{item?.Fuente_de_Posible_cliente || "NA"}</b> /
                Industry - <b>{item?.Industry1 || "NA"}</b>
                <br></br>
                Email - <b>{item?.Email || "NA"}</b> / State -{" "}
                <b>{item?.Estado_Unificado || "NA"}</b> / Country -{" "}
                <b>{item?.Pais || "NA"}</b>
              </div>
            </Panel>
          ))}
        </Collapse> </Card> </> : (
        <Alert
          showIcon={false}
          className="new_order_column"
          type="error"
          banner
          message="No Account found!"
        />
      )}
      </Card>
      <AddAccount
        accountModalVisible={accountModalVisible}
        handleAccountModalVisible={handleAccountModalVisible}
        linkAccout={linkAccout}
      />
    </>
  );
}

export default LinkAccount