/* eslint-disable no-sparse-arrays */
import {
  CopyOutlined,
  FileSyncOutlined,
  MoreOutlined,
  SelectOutlined,
  SendOutlined,
  SyncOutlined,
  FilePdfTwoTone,
  CheckSquareOutlined
} from "@ant-design/icons";
import ProTable from "@ant-design/pro-table";
import { getUser } from "../../../helpers";
import marbleApi from "../../../services/http/marbleApi";

import {
  ModalForm,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Button, Card, Dropdown, Menu, message, Tooltip } from "antd";
import { useRef, useState } from "react";

function QuotePDF(props) {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, handleModalVisible] = useState(false);
  const [quote, setQuote] = useState(null);

  const { order, syncOrder } = props;
  const actionRef = useRef();

  const menu = (
    <Menu>
      <Menu.Item icon={<SyncOutlined />}>Create Quote</Menu.Item>
    </Menu>
  );

    const createQuote = async () => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      message.loading({
        content: "creating Quote...",
        key: "createQuote",
        duration: 0,
      });

      try {
        await marbleApi.http.post(marbleApi.urls.quote.create, {
          order_id: order?.id,
        });
        message.success({
          content: "Quote Created",
          key: "createQuote",
        });
        await syncOrder();
        return true;
      } catch (error) {
        message.error({
          content: error.message,
          key: "createQuote",
        });
        return false;
      }
    };
  }

  const sendQuote = async (value) => {
    if (getUser().role !== "ADMIN" && getUser().id !== order?.User?.id) {
      message.error("only order owner can make changes");
    }
    else {
      message.loading({
        content: "Sending Quote...",
        key: "sendQuote",
        duration: 0,
      });

      try {
        await marbleApi.http.post(marbleApi.urls.quote.send, {
          quote:quote ,
          ...value,
          order:order,
        });
        message.success({
          content: "Quote Sent",
          key: "sendQuote",
        });
        await syncOrder();
        return true;
      } catch (error) {
        message.error({
          content: error.message,
          key: "sendQuote",
        });
        return false;
      }
    };
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Generated at",
      dataIndex: "createdAt",
      valueType: "textarea",
    },

    {
      title: "Sent",
      dataIndex: "sent",
      valueType: "checkbox",
      render: (dom, entity) => {
        return entity.sent ? "Yes" : "No";
      },
    },
    {
      title: "Sent At",
      dataIndex: "updatedAt",
      valueType: "textarea",
    },
    {
      title: "",
      dataIndex: "viewed_at",
      valueType: "textarea",
      render: (dom, entity) => [
        <Tooltip title="Copy Quote PDF Link" key={1}>
          <Button
            type="link"
            onClick={() => {
              navigator.clipboard.writeText(entity?.pdf.url);
              message.success("Copied to clipboard");
            }}
          >
            <CopyOutlined /> Copy link
          </Button>
        </Tooltip>,
        /*  <Button type="link" key={2}>
          <DownloadOutlined /> Download
        </Button> */
        <Button
          type="link"
          key={3}
          onClick={() => {
            window.open(entity?.pdf.url, "_blank");
          }}
        >
          <SelectOutlined /> preview
        </Button>,
        entity.sent === false ? <Button
        type="link"
        key={4}
        onClick={() => {
          handleModalVisible(true)
          setQuote(entity)
        }}
      >
        <SendOutlined /> send
        </Button> :
          <Button
          type="text"
          disabled
          key={5}
        >
          <CheckSquareOutlined /> Sent
          </Button>
      ],
    },
  ];

  return (
    <>
      <Card
        title={
          <span>
            <FileSyncOutlined /> Quotes
          </span>
        }
        bordered={false}
        style={{}}
        extra={
          <>
            <Button
              type="link"
              onClick={() => {
                createQuote()
                // handleModalVisible(true);
              }}
            >
              <FilePdfTwoTone /> Create Quote
            </Button>
            {/* <Dropdown overlay={menu}>
              <Button type="link">
                <MoreOutlined />
              </Button>
            </Dropdown> */}
          </>
        }
        actions={[]}
      >
        <ProTable
          pagination={false}
          loading={false}
          headerTitle={false}
          search={false}
          options={false}
          dataSource={order?.quotes}
          columns={columns}
        />
      </Card>

      <ModalForm
        title="📨 Send Quote"
        width="60vw"
        visible={createModalVisible}
        onVisibleChange={handleModalVisible}
        submitter={{
          searchConfig: {
            submitText: "Send Quote",
          },
        }}
        onFinish={async (value) => {
          const success = await sendQuote(value);

          if (success) {
            handleModalVisible(false);

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
        initialValues={{
          email: order?.client?.email,
          subject: `Quote for ${order?.description}`,
          body: `Hi ${order?.client?.name}, Please find the quote for "${order?.description}" attached. Thank you for your business. - ${order?.User?.name}`,
        }}
      >
        <ProFormText
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
          label="Contact Email"
          name="email"
          placeholder={"Enter email"}
        />
        <ProFormText
          rules={[
            {
              required: true,
            },
          ]}
          label="Email Subject"
          name="subject"
          placeholder={"Enter email subject"}
        />
        <ProFormTextArea
          rules={[
            {
              required: true,
            },
          ]}
          rows={6}
          label="Email Body"
          name="body"
          placeholder={"Enter email body"}
        />
      </ModalForm>
    </>
  );
}

export default QuotePDF;
