import ImageUploadShopify from "../../components/Common/ImageUploadShopify";
import {
  DeleteOutlined,
  EllipsisOutlined,
  PlusOutlined,
  SyncOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { PageContainer } from "@ant-design/pro-layout";
import ProTable from "@ant-design/pro-table";
import { debounce } from "../../helpers";
import {
  Button,
  Dropdown,
  Layout,
  Menu,
  message,
  Modal,
  Radio,
  Space,
  Tabs,
  Upload,
  ConfigProvider,
  Tooltip,
} from "antd";
import { useRef, useState,useEffect } from "react";
import marbleApi from "../../services/http/marbleApi";
import styles from "./index.less";
import enUSIntl from "antd/lib/locale/en_US";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Dragger } = Upload;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const Images = () => {
  const actionRef = useRef();
  const [selectedRowsState, setSelectedRows] = useState([]);

  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [nested, setNested] = useState({});
  const [fileList, setfileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://cdn.shopify.com/s/files/1/0555/7229/9970/products/QUARTZITEFUSIONWOWDARK_300x200.jpg",
    },
  ]);

  const handleCancel = () => setpreviewVisible(false);

  useEffect(() => {
    debounce(() => actionRef.current.reload())();
  }, [nested]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  //  filter
  const updateNested = (key, value) => {
    setNested((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const listBlocks = async (params, options) => {
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.products.listBlocks,
        {
          params,
          options,
          nested,
        }
      );
      if (response) {
        return response;
      }
    } catch (error) {
      message.error(error?.message);
      return {};
    }
  };

  const handleChange = ({ fileList }) => setfileList(fileList);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      tip: "Material Name",
      render: (dom, entity) => {
        return (
          <Tooltip title="View on Shopify">
            <a
              href={`https://marbleunlimitedinc.com/collections/materials/products/${entity?.shopifyHandle}`}
              target="_blank"
              rel="noreferrer"
            >
              <h4> {dom} <LinkOutlined style={{color:'#2F54EB'}} /></h4>
            </a>
          </Tooltip>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      valueType: "textarea",
    },
    {
      title: "Block",
      dataIndex: "blockId",
      valueType: "textarea",
    },

    {
      title: "Images",
      dataIndex: "images",
      sorter: true,
      search: false,
      hideInForm: true,
      render: (dom, entity) =>
        entity.shopifyProductId ? (
          <ImageUploadShopify
            limit={20}
            images={entity.images.map((image) => ({
              uid: image.id,
              prefix: image.prefix,
              name: image.name,
              status: "done",
              url: image.url,
            }))}
            slug="shopify"
            block={entity}
          />
        ) : (
          <>⚠ Not on Shopify</>
        ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      valueType: "textarea",
      sorter: true,
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <SyncOutlined /> Sync Products From SAP
      </Menu.Item>
    </Menu>
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const props = {
    name: "file",
    multiple: true,
    className: styles.dragger,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <ConfigProvider locale={enUSIntl}>
      <PageContainer title="StonePro">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Manual Upload" key="1">
            <ProTable
              headerTitle="Images"
              actionRef={actionRef}
              rowKey="key"
              search={{
                labelWidth: 140,
              }}
              options={{
                fullScreen: false,
                reload: true,
                density: false,
                setting: false,
              }}
              toolBarRender={() => [
                <Radio.Group defaultValue={null}
                  className='order_radio'
                  buttonStyle="solid"
                  onChange={(e) => updateNested("images_length", e.target.value)}
                >
                  <Radio.Button value={null}>All</Radio.Button>
                  <Radio.Button value="SHOPIFY" >Product With Images</Radio.Button>
                  <Radio.Button value="not">Product Without Images</Radio.Button>
                </Radio.Group>,
                <Dropdown key="menu" overlay={menu}>
                  <Button type="link">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>,
              ]}
              request={listBlocks}
              columns={columns}
              rowSelection={{
                onChange: (_, selectedRows) => {
                  setSelectedRows(selectedRows);
                },
              }}
              tableAlertRender={({
                selectedRowKeys,
                selectedRows,
                onCleanSelected,
              }) => (
                <Space size={24}>
                  <span>
                    {selectedRowKeys.length} products selected
                    <a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                      Clear Selection
                    </a>
                  </span>
                </Space>
              )}
              tableAlertOptionRender={() => {
                return (
                  <Space size={16}>
                    <Button key={1} type="link" danger>
                      <DeleteOutlined /> Delete Images
                    </Button>
                  </Space>
                );
              }}
            />
          </TabPane>
          {/*  <TabPane tab="Automatic Upload" key="2">
          <div style={{ margin: 10 }}>
            <Alert
              message=" Please make sure image name contains product SKU. You can also add image position to
            image name. eg. SKU_1, SKU_2."
              type="info"
              showIcon
            />
          </div>
          <div>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag Images to this area to upload</p>
              <p className="ant-upload-hint">You can upload multiple product images all at once.</p>
            </Dragger>
          </div>
        </TabPane> */}
        </Tabs>

        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Images;
