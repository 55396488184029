import React, { useReducer } from "react";
import { getUser } from "../helpers";

import appContext from "./app-context";
import {
  appReducer,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CLEAR_CART,
} from "./reducers";

const GlobalState = (props) => {
  // const [cart, setCart] = useState([]);
  const [cartState, dispatch] = useReducer(appReducer, {
    cart: getUser()?.cart || [],
  });

  const addProductToCart = (product) => {
    setTimeout(() => {
      dispatch({ type: ADD_PRODUCT, product: product });
    }, 100);
  };

  const removeProductFromCart = (productId) => {
    setTimeout(() => {
      dispatch({ type: REMOVE_PRODUCT, productId: productId });
    }, 100);
  };

  const clearCart = () => {
    setTimeout(() => {
      dispatch({ type: CLEAR_CART });
    }, 100);
  };

  return (
    <appContext.Provider
      value={{
        cart: cartState.cart,
        addProductToCart: addProductToCart,
        removeProductFromCart: removeProductFromCart,
        clearCart: clearCart,
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};

export default GlobalState;
