import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, message, Modal } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import marbleApiService from "../../services/http/marbleApi";

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export default function Signature(props) {
  const { callback, setVisible } = props;
  const [modalVisible, setModalVisible] = useState(true);
  let sigPad = {};

  const hidemodal = () => {
    setVisible(false);
    setModalVisible(false);
  };

  const saveSignature = async () => {
    const hide = message.loading("uploading signature...");
    let formData = new FormData();
    var block = sigPad.getTrimmedCanvas().toDataURL("image/png").split(";");
    var contentType = "image/png";
    var realData = block[1].split(",")[1];
    var signature = b64toBlob(realData, contentType);
    formData.append("file", signature, "signature.png");
    formData.append("slug", "signature");
    try {
      const response = await marbleApiService.http.post(
        marbleApiService.urls.asset.upload,
        formData
      );
      hide();
      message.success("uploaded successfully");
      if (callback) callback("upload", response?.body);
      hidemodal();
    } catch (error) {
      hide();
      message.error(error.message);
    }
  };

  return (
    <Modal
      title={
        <>
          <EditTwoTone /> Sign
        </>
      }
      centered
      maskClosable={false}
      visible={modalVisible}
      okText="Save Signature"
      onOk={() => saveSignature()}
      onCancel={() => hidemodal()}
      width="60%"
      style={{}}
      bodyStyle={{
        backgroundColor: "#84858747",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <SignatureCanvas
        canvasProps={{ width: 720, height: 280, className: "sigCanvas" }}
        ref={(ref) => {
          sigPad = ref;
        }}
      />
    </Modal>
  );
}
