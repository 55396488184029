import { getFromLocalStorage, updateCart, waitTime, getUser } from "../../../helpers";
import {
  EditOutlined,
  QuestionOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  ProFormGroup,
  ModalForm,
  ProFormCheckbox,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-form";
import ProTable from "@ant-design/pro-table";
import {
  Card,
  Button,
  Checkbox,
  Form,
  message,
  Space,
  Tag,
  Tooltip,
  Modal,
  Divider,
} from "antd";
import { union } from "lodash";
import React, { useState, useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import marbleApi from "../../../services/http/marbleApi";
import ImageUploadSlab from "../../../components/Common/ImageUploadSlab";
import { warehouses } from "../../../constants";
import "./style.less";
import appContext from "../../../context/app-context";
const { confirm } = Modal;

function Slabs(props) {
  const actionRef = useRef();
  const [form] = Form.useForm();
  const [selectedRowsState, setSelectedRows] = useState([]);
  const [currentSlab, setCurrentSlab] = useState({});
  const [slabModal, setslabModal] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [myprice, setMyPrice] = useState(null);
  const [mode, setmode] = useState("single");
  const [urlll, setUrl] = useState();
  const [slabFiltered, setSlabFiltered] = useState(null);

  const user = getFromLocalStorage("user");

  const { slabs, product_id, getProduct, product, callback,block,nested } = props;
  const normalizeSlab = (slab) => {
    slab.price = {
      price: slab?.price_retail ||block?.price_retail || product.price_retail,
      professional:slab?.price_professional ||block?.price_professional || product.price_professional,
      retail:slab?.price_retail ||block?.price_retail || product.price_retail,
      wholesale: slab?.price_wholesale ||block?.price_wholesale || product.price_wholesale,
    };
    return slab;
  };

  const edit = async (id, mode = "single") => {
    if (mode === "single") {
      await setmode("single");
      const slab = slabs.find((slab) => slab.id === id);
      await setCurrentSlab(slab);
    } else {
      await setmode("multiple");
      await setCurrentSlab({});
    }
    setslabModal(true);
  };

  useEffect(() => {
    console.log(nested?.availability,'availabilityy')
    if (nested?.availability) {
      if (nested?.availability === 'committed') {
        setSlabFiltered(slabs?.filter(
          (i) => i.commited > 0 || i.hold === true
        ))
      }
      if (nested?.availability === 'available') {
        console.log('i m happening')
        setSlabFiltered(slabs?.filter(
          (i) => i.commited === 0 
        ))
      }
    }
  }, [nested]); 

  useEffect(() => {
    getProduct(product_id);
  }, [urlll]); 

  const handleUpdate = async (fields, entity = null) => {
    const hide = message.loading("Updating");

    if (fields?.damaged) {
      fields.inspected = true;
    }

    if (fields?.damaged === false) {
      fields.damagedPercentage = null;
    }
    const slab = entity || currentSlab;

    if ("height" in fields && "width" in fields) {
      confirm({
        title: "Are you sure?",
        icon: <QuestionOutlined />,
        okText: "Yes",
        content:
          "You are changing the size that is registered in SAP, are you sure you want to proceed?",
        onOk: async () => {
          fields.existing =
            Math.round(((fields.height * fields.width) / 144) * 1e2) / 1e2;
          fields.size =
            Math.round(((fields.height * fields.width) / 144) * 1e2) / 1e2;
          try {
            await marbleApi.http.post(marbleApi.urls.products.updateSlab, {
              slab_ids: slab?.id
                ? [slab.id]
                : selectedRowsState.map((row) => row.id),
              data: fields,
            });
            hide();
            message.success("Updated successsfully");
            // selecting bug on edit
            const data = selectedRowsState.map((s) => {
              const temp = { ...s };
                temp.width = fields.width;
                temp.height = fields.height;
                temp.existing = fields.existing;
                temp.size = fields.size;
                return temp;
            })
            if (callback)
                  callback(
                    data.map((row) => normalizeSlab(row)),
                    product_id
                  );
            await getProduct(product_id);
            setslabModal(false);
            return true;
          } catch (error) {
            hide();
            message.error(error.message);
            setslabModal(false);
            return false;
          }
        },
        onCancel() {
          return false;
        },
      });
    }
    else{
      try {
        await marbleApi.http.post(marbleApi.urls.products.updateSlab, {
          slab_ids: slab?.id
            ? [slab.id]
            : selectedRowsState.map((row) => row.id),
          data: fields,
        });
        hide();
        message.success("Updated successsfully");
        // selecting bug on edit
        const data = selectedRowsState.map((s) => {
          const temp = { ...s };
            temp.width = fields.width;
            temp.height = fields.height;
            temp.existing = fields.existing;
            temp.size = fields.size;
            return temp;
        })
        if (callback)
              callback(
                data.map((row) => normalizeSlab(row)),
                product_id
              );
        await getProduct(product_id);
        setslabModal(false);
        return true;
      } catch (error) {
        hide();
        message.error(error.message);
        setslabModal(false);
        return false;
      }
    }
  };

  return (
    <appContext.Consumer>
      {(context) => (
        <React.Fragment>
          <ProTable
            columns={[
              {
                title: "Slab",
                dataIndex: "slabId",
                valueType: "textarea",
              },
              {
                title: "Size",
                dataIndex: "existing",
                valueType: "textarea",
                // renderText: (value) => `${value} Sqf.`,
                render: (dom, entity) =>
                  `${entity.size ? entity.size : entity.existing}`,
              },
              {
                title: "Dimension",
                dataIndex: "dimensions",
                valueType: "textarea",
                render: (dom, entity) =>
                  `${entity.height || "na"} x ${entity.width || "na"}`,
              },
              {
                title: "Price",
                dataIndex: "price",
                valueType: "textarea",
                className: 'name-column',
                render: (dom, entity) => {
                  return (
                    getUser().role === "STONECLUB"?  <p style={{textAlign:'center'}}>{entity?.price?.retail}</p> :
                    <p>
                      Retail  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {entity?.price?.retail}
                      <br />
                      Professional &nbsp;{entity?.price?.professional}
                      <br />
                      Wholesale  &nbsp; &nbsp; {entity?.price?.wholesale}                     
                    </p>
                    )
                },
              },
              {
                title:<Tooltip  title="Discount">Disc..</Tooltip>,
                dataIndex: "discount",
                valueType: "textarea",
                className: getUser().role === "STONECLUB" && 'hidden',
                render: (dom, entity) => {
                  return (
                    entity.discount && (
                      <span>
                        {entity.discount}
                        {entity.discountType === "amount" ? "$" : "%"}
                      </span>
                    )
                  );
                },
              },
              {
                title: "Available",
                dataIndex: "existing",
                valueType: "textarea",
              },

              // {
              //   title: "Available",
              //   dataIndex: "available",
              //   valueType: "textarea",
              //   // renderText: (value) => `${value} Sqf.`,
              //   render: (dom, entity) =>
              //     `${
              //       entity.available === 0
              //         ? entity.available + " Sqf"
              //         : entity.existing
              //     }`,
              // },
              {
                title: "Status",
                dataIndex: "hold",
                valueType: "textarea",
                render: (dom, entity) =>
                  dom === true ? (
                    <Tag style={{ textAlign: 'center' }} color={"red"}>On hold by <br />{entity.salesPerson || ""} <br />
                    {entity.orderID || ""}
                    </Tag>
                  ): entity.available === 0 && entity.existing === 0 ? (
                    <Tag style={{textAlign:'center'}} color={"red"}> SOLD</Tag>
                  )
                    : entity.available === 0 ? (
                    <Tag style={{textAlign:'center'}} color={"red"}>Committed by <br/>{entity?.salesPerson || '-No Sales Employee-'} </Tag>
                  ) : (
                    <Tag color={"green"}> </Tag>
                  ),
                // dom === true ? (
                //   <Tag color={"red"}>{`On hold by ${
                //     entity.salesPerson || ""
                //   } ${entity.orderID || ""}`}</Tag>
                // ) : (
                //   <Tag color={"green"}> </Tag>
                // ),
              },
              {
                title:<Tooltip  title="Warehouse">Ware..</Tooltip>,
                dataIndex: "warehouse",
                valueType: "textarea",
                renderText: (value) => (
                  <Tag color={"blue"}>{warehouses[`${value}`]}</Tag>
                ),
              },
              {
                title: "Images",
                dataIndex: "images",
                search: false,
                hideInForm: true,
                render: (dom, entity) => (
                  <ImageUploadSlab
                    limit={20}
                    images={
                      entity.images?.map((image) => ({
                        uid: image.id,
                        prefix: image.prefix,
                        name: image.name,
                        status: "done",
                        url: image.url,
                        uploader: image?.User?.name,
                        source:image?.source
                      })) || []
                    }
                    slug="slab"
                    slab={entity}
                    block={block}
                    callback={setUrl}
                    />
                ),
              },
              {
                title:  <Tooltip  title='Inspected'>Insp..</Tooltip>,
                dataIndex: "inspected",
                valueType: "checkbox",
                className: getUser().role === "STONECLUB" && 'hidden',
                render: (dom, entity) => {
                  return (
                    <>
                      <Checkbox
                        defaultChecked={entity.inspected}
                        onChange={async (e) => {
                          await handleUpdate(
                            { inspected: e.target.checked },
                            entity
                          );
                        }}
                      />
                    </>
                  );
                },
              },
              {
                title:<Tooltip  title="Damaged">Dama..</Tooltip>,
                dataIndex: "damaged",
                valueType: "checkbox",
                className: getUser().role === "STONECLUB" && 'hidden',
                render: (dom, entity) => {
                  return (
                    <>
                      <Space>
                        <Checkbox
                          defaultChecked={entity.damaged}
                          onChange={async (e) => {
                            await handleUpdate(
                              { damaged: e.target.checked },
                              entity
                            );
                          }}
                        />
                        {entity.damagedPercentage ? (
                          <Tag color="red">
                            {entity.damagedPercentage + "%"}
                          </Tag>
                        ) : null}
                      </Space>
                    </>
                  );
                },
              },
              {
                title: "",
                dataIndex: "operation",
                key: "operation",
                valueType: "option",
                className: getUser().role === "STONECLUB" && 'hidden',
                render: (dom, entity) => [
                  <Tooltip
                    title="Edit Slab"
                    key={1}
                    disabled={!["ADMIN", "WAREHOUSE"].includes(user.role)}
                  >
                    <Button type="text" onClick={() => edit(entity.id)}>
                      <EditOutlined />
                    </Button>
                  </Tooltip>,
                  <Tooltip title="Add to cart" key={2}>
                    {" "}
                    <Button
                      type="link"
                      onClick={context.addProductToCart.bind(this, [
                        normalizeSlab(entity),
                      ])}
                      disabled={
                        entity.available == 0 ||
                        !["ADMIN", "SALES"].includes(user.role)
                      }
                    >
                      <ShoppingCartOutlined />
                    </Button>
                  </Tooltip>,
                ],
              },
            ]}
            headerTitle={false}
            search={false}
            options={false}
            pagination={false}
            dataSource={slabFiltered || slabs}
            actionRef={actionRef}
            rowClassName={(record) =>
              (record.available == 0 || record.hold) ? "disabled-row" : getUser().role === "STONECLUB" ? "uneditable-row" : null
              // (record.available == 0 || record.hold) && "disabled-row"
            }
            rowKey="id"
            rowSelection={{
              getCheckboxProps: (record) => ({
                disabled: record.available == 0 || record.hold || getUser().role === "STONECLUB",
                key: record.id,
              }),
              onChange: (_, selectedRows) => {
                if (callback)
                  callback(
                    selectedRows.map((row) => normalizeSlab(row)),
                    product_id
                  );
                setSelectedRows(selectedRows);
              },
            }}
            tableAlertRender={({
              selectedRowKeys,
              selectedRows,
              onCleanSelected,
            }) => (
              <Space size={24}>
                <span>
                  {selectedRowKeys.length} products selected
                  <a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                    Clear Selection
                  </a>
                </span>
              </Space>
            )}
            tableAlertOptionRender={() => {
              return (
                <Space size={16}>
                   <Link key={1} to="#" onClick={() => edit("", "multiple")}>
                    <EditOutlined /> Edit{" "}
                  </Link>
                  <Link
                    key={2}
                    to="#"
                    onClick={() => {
                      context.addProductToCart(
                        selectedRowsState.map((row) => normalizeSlab(row))
                      );
                    }}
                  >
                    <ShoppingCartOutlined /> Add to Cart
                  </Link>
                </Space>
              );
            }}
          />
          <ModalForm
            title="✅ Update Slab"
            width="400px"
            visible={slabModal}
            onVisibleChange={setslabModal}
            onFinish={async (value) => {
              const success = await handleUpdate(value);
            }}
            submitter={{
              searchConfig: {
                submitText: "Update",
              },
            }}
          >
            <h4>Dimentions</h4>
            <ProFormDigit min={1} label="Length" name="height" />
            <ProFormDigit min={1} label="Width" name="width" />
            {getUser().email === "jmijares@marbleunlimitedinc.com" &&
              <>
            <Divider />
            <h4>Price</h4>
                  <ProFormDigit
                    name="price_retail"
                    label="Retail"
                  />
                  <ProFormDigit
                    name="price_professional"
                    label="Professional"
                  />
                  <ProFormDigit
                    name="price_wholesale"
                    label="Wholesale"
              />
              </>
            }

            {mode === "single" && (
              <>
              <Divider />
              <h4>Damaged Info</h4>
                <ProFormCheckbox name="damaged" label="Damaged" />
                <ProFormDigit
                  name="damagedPercentage"
                  label="Damaged percentage"
                />
                <Divider />
                <h4>Discount</h4>
                <ProFormGroup>
                  <ProFormDigit
                    onChange={(value) => setDiscount(value)}
                    name="discount"
                    label="Discount"
                  />
                  <ProFormSelect
                    name="discountType"
                    label="Type"
                    placeholder=""
                    rules={[
                      {
                        required: discount ? true : false,
                        message: "Required!",
                      },
                    ]}
                    options={[
                      {
                        value: "percent",
                        label: "%",
                      },
                      {
                        value: "amount",
                        label: "$",
                      },
                    ]}
                  />
                </ProFormGroup>
              </>
            )}
          </ModalForm>
        </React.Fragment>
      )}
    </appContext.Consumer>
  );
}

export default Slabs;
