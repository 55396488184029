import React, { useEffect, useState, useContext, useRef } from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { PlusOutlined } from "@ant-design/icons";
import {Popconfirm, Typography, message, Card, Row, Button, Table, Col,Input,InputNumber,Form } from "antd";
import ProForm, { ProFormDigit } from "@ant-design/pro-form";
import marbleApi from "../../services/http/marbleApi";
import { debounce } from "../../helpers";
import "./index.css";

// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';



function PriceDashboard() {
  const [form] = ProForm.useForm();
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [filter, setFilter] = useState(null);
  const [nested, setNested] = useState(null);
  const [retail, setRetail] = useState([]);
  const [wholesale, setWholesale] = useState([]);
  const [professional, setProfessional] = useState([]);
  const [editValues, setEditValues] = useState([]);
  const [bulkEdit, setBulk] = useState([]);
  const [bulkEditValues, setBulkEditValues] = useState({});
  const [showButton, setShowButton] = useState(false);

  
  useEffect(() => {
    debounce(() => getProducts(), 800)();
  }, [filter, nested]);

  const replaceKeyRecursive = (obj, replacements) => {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }
    const newObj = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (replacements.hasOwnProperty(key)) {
        newObj[replacements[key]] = replaceKeyRecursive(obj[key], replacements);
      } else {
        newObj[key] = replaceKeyRecursive(obj[key], replacements);
      }
    }
  
    return newObj;
  };
  
  async function getProducts() {
    const params = {
      current: 1,
      pageSize: 2000,
    };
    try {
      const response = await marbleApi.http.post(marbleApi.urls.products.listBlocks, {
        params: { ...params, ...filter },
        nested,
      });
      if (response) {
        const keyReplacements = {
          // blocks: 'children',
          slabs: 'children',
        };
            
        const newArray = response.data.map(obj => replaceKeyRecursive(obj, keyReplacements));
        setRowData(newArray);
      }
    } catch (error) {
      message.error(error?.message);
    }
  }
  
  const updateFilter = (key, value) => {
    if ((Array.isArray(value) && value.length === 0) || value === "") {
      setFilter((prevData) => {
        const newData = { ...prevData };
        delete newData[key];
        return newData;
      });
      return;
    }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  
  const updateNested = (key, value) => {
    setNested((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '400px',
      // render: (dom, entity) => {
      //   return (
      //     <p>{entity.slabId ? 'Slab:' : entity.blockId ? 'Block: ' : entity.name}</p>
      //   )
      // }
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      // width: '12%',
    },
    {
      title: 'Block Id',
      dataIndex: 'blockId',
      render: (dom, entity) => {
        return (
          <p>{entity.slabId ? entity.blockIdSAP : entity.blockId}</p>
        )
      }
    },
    {
      title: 'Slab Id',
      dataIndex: 'slabId',
      width: '180px',
    },
    {
      title: 'Price Professional',
      dataIndex: 'price_professional',
      sorter: true,
      render: (dom, entity) => {
        return (
          <InputNumber
            disabled={!entity.blockId}
            defaultValue={entity.price_professional}
            onChange={(value) => {
              entity.price_professional = value
              const index = editValues.findIndex(
                (i) => i.id === entity.id
              );
              if (index !== -1) {
                editValues[index].price_professional = value;
                setEditValues(editValues)
              }
              else {
                editValues.push(entity)
              }
              setShowButton(true)
            }}
            controls={false}
          />
        )
      }
    },
    {
      title: 'Price Retail',
      dataIndex: 'price_retail',
      sorter: true,
      render: (dom, entity) => {
        return (
          <InputNumber
            disabled={!entity.blockId}
            defaultValue={entity.price_retail}
            onChange={(value) => {
              entity.price_retail = value
              console.log(entity, 'entity')
              const index = editValues.findIndex(
                (i) => i.id === entity.id
              );
              if (index !== -1) {
                editValues[index].price_retail = value;
                setEditValues(editValues)
              }
              else {
                editValues.push(entity)
              }
              setShowButton(true)
            }}
            
            controls={false}
          />
        )
      }
    },
    {
      title: 'Price Wholesale',
      dataIndex: 'price_wholesale',
      sorter: true,
      render: (dom, entity) => {
        return (
          <InputNumber
            disabled={!entity.blockId}
            defaultValue={entity.price_wholesale}
            onChange={(value) => {
              entity.price_wholesale = value
              console.log(entity, 'entity')
              const index = editValues.findIndex(
                (i) => i.id === entity.id
              );
              if (index !== -1) {
                editValues[index].price_wholesale = value;
                setEditValues(editValues)
              }
              else {
                editValues.push(entity)
              }
              setShowButton(true)
            }}
            controls={false}
          />
        )
      }
    },
  ];

  const handleFinish = async (values) => {
    const hide = message.loading("Updating");
    if (bulkEdit.length > 0) {
       var bulkSlabEdits = bulkEdit.filter(obj => obj.hasOwnProperty('slabId'));
       var bulkBlockEdits = bulkEdit.filter(obj => obj.hasOwnProperty('blockId') && !obj.hasOwnProperty('slabId'));
    }
    if (editValues.length > 0) {
     var slabsEdits = editValues.filter(obj => obj.hasOwnProperty('slabId'));
     var slabsBlockEdits = editValues.filter(obj => obj.hasOwnProperty('blockId') && !obj.hasOwnProperty('slabId'));
    }
    try {
        const response = await marbleApi.http.post(marbleApi.urls.products.priceUpdate, {
          slabsEdits,
          slabsBlockEdits,
          bulkSlabEdits,
          bulkBlockEdits,
          data: { price_retail: retail, price_professional: professional, price_wholesale: wholesale }
        });
      if (response) {
        hide();
        message.success("Updated successsfully");
        return true;
      }
    } catch (error) {
      hide();
      message.error(error.message);
      return false;
    }
  };


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setBulk(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: !record.blockId, // Disable the checkbox for rows with disableSelection: true
    }),
  };





  return (
    <>
      <Card >
        <Row gutter={4} className="filter-top-row">
          <h3>Filter </h3> {" "}
            <Col>
              <Input
                placeholder="Name"
                allowClear
                onChange={(e) => updateFilter("name", e.target.value)}
              />
            </Col>
            <Col>
              {" "}
              <Input
                placeholder="Block"
                allowClear
                onChange={(e) => updateFilter("blockId", e.target.value)}
              />
            </Col>
            <Col>
              {" "}
              <Input
                placeholder="Slab"
                allowClear
                onChange={(e) => updateNested("slabid_block", e.target.value)}
              />
          </Col> 
        </Row>  
        {/* {bulkEdit.length > 0 && <h3>Edit</h3> } */}
        <Row gutter={4} className="filter-top-row">
          {bulkEdit.length > 0 &&
            <>
            <h3>Edit</h3>
              <Col>
              <InputNumber
                style={{ width: '200px' }}
                placeholder="Price Retail"
                allowClear
                disabled={bulkEdit.length === 0}
                name='price_retail'
              onChange={(e) => {
                setRetail(e)
                setShowButton(true)
              }}
              />
            </Col>
            <Col>
              {" "}
              <InputNumber
                placeholder="Price Professional"
                style={{ width: '200px' }}
                name='price_professional'
                allowClear
                onChange={(e) => {
                setProfessional(e)
                setShowButton(true)
              }}
                disabled={bulkEdit.length === 0}
              />
            </Col>
            <Col>
              {" "}
              <InputNumber
                placeholder="Price Wholesale"
                style={{ width: '200px' }}
                allowClear
              onChange={(e) => {
                setWholesale(e)
                setShowButton(true)
              }}
                name='price_wholesale'
                disabled={bulkEdit.length === 0}
              />
          </Col></>}
          { showButton &&
            <Col>
            {" "}
            <Button type='primary' onClick={handleFinish}>Save</Button>
            {" "}
            <Button >Cancel</Button>
            </Col>}
          </Row>  
      </Card>
         <Table
        columns={columns}
        loading={!rowData}
        scroll={{y:"calc(100vh - 220px)", x:'auto' }}
        rowSelection={{
          ...rowSelection,
        }}
        rowClassName={() => 'editable-row'}
        dataSource={rowData}
        rowKey='id'
      />
</>

  )
}

export default PriceDashboard