import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import ProTable from "@ant-design/pro-table";

export default function Reports(props) {
  const { order } = props;

  const profit = (cp, sp) => {
    return {
      amount: (sp - cp).toFixed(2),
      percent: (((sp - cp) / sp) * 100).toFixed(2),
    };
  };

  const orderCP = (order) => {
    let cp = 0;

    order.orderSlabs.forEach((slab) => {
      if (slab.Slab?.size) {
        cp += (slab.price.retail / 2) * slab.Slab?.size;
      }
      else {
        cp += (slab.price.retail / 2) * slab.Slab?.existing;
      }
    });
    order.specialProducts.forEach((slab) => {
      cp += (slab.price / 2) * slab.size;
    });
    return cp;
  };

  const data = (order) => {
    let data = [];

    order.orderSlabs.forEach((slab) => {
      let p = 0
      if (slab.Slab?.size) {
      p = profit((slab.price.retail / 2) * slab.Slab?.size, slab.finalPrice);
       }
      else {
        p = profit((slab.price.retail / 2) * slab.Slab?.existing, slab.finalPrice);
      }
      // let p = profit((slab.price.retail / 2) * slab.Slab?.size, slab.finalPrice);
      data.push({
        name: slab.Slab.name,
        id: slab.Slab.slabId,
        sp: slab.finalPrice,
        profit: p.amount,
        profitPercentage: p.percent,
      });
    });

    order.specialProducts.forEach((slab) => {
      let p = profit((slab.price / 2) * slab.size, slab.finalPrice);
      data.push({
        name: slab.name,
        id: slab.slab,
        sp: slab.finalPrice,
        profit: p.amount,
        profitPercentage: p.percent,
      });
    });

    return data;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      valueType: "textarea",
    },
    {
      title: "Product Id",
      dataIndex: "id",
      valueType: "textarea",
    },
    {
      title: "Selling Price",
      dataIndex: "sp",
      valueType: "textarea",
      render: (dom, entity) => {
        return `$ ${dom}`;
      },
    },
    {
      title: "Profit amount",
      dataIndex: "profit",
      valueType: "textarea",
      render: (dom, entity) => {
        if (dom > 0) {
          return <Statistic value={dom} precision={2} prefix="💲" />;
        } else {
          return <Statistic value={dom} precision={2} prefix="🔴 $" />;
        }
      },
    },
    {
      title: "Profit %",
      dataIndex: "profitPercentage",
      valueType: "textarea",
      render: (dom, entity) => {
        if (dom > 0) {
          return (
            <Statistic
              value={dom}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              suffix={<PercentageOutlined />}
            />
          );
        } else {
          return (
            <Statistic
              value={dom}
              precision={2}
              valueStyle={{ color: "red" }}
              suffix={<PercentageOutlined />}
            />
          );
        }
      },
    },
  ];

  return (
    <>
      <Row style={{ margin: 20 }}>
        <Col style={{ marginRight: 20 }}>
          <Card>
            {profit(orderCP(order), order.totalPrice).amount > 0 ? (
              <Statistic
                title="Total Profit (Inc Tax)"
                value={profit(orderCP(order), order.totalPrice).amount}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix=" USD"
              />
            ) : (
              <Statistic
                title="Total Profit (Inc Tax)"
                value={profit(orderCP(order), order.totalPrice).amount}
                precision={2}
                valueStyle={{ color: "red" }}
                prefix={<ArrowDownOutlined />}
                suffix=" USD"
              />
            )}
          </Card>
        </Col>
        <Col>
          <Card>
            {profit(orderCP(order), order.totalPrice).percent > 0 ? (
              <Statistic
                title="Total Profit Percentage (Inc Tax)"
                value={profit(orderCP(order), order.totalPrice).percent}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix=" %"
              />
            ) : (
              <Statistic
                title="Total Profit Percentage (Inc Tax)"
                value={profit(orderCP(order), order.totalPrice).percent}
                precision={2}
                valueStyle={{ color: "red" }}
                prefix={<ArrowDownOutlined />}
                suffix=" %"
              />
            )}
          </Card>
        </Col>
      </Row>

      <ProTable
        pagination={false}
        loading={false}
        headerTitle={false}
        search={false}
        options={false}
        dataSource={data(order)}
        columns={columns}
      />
    </>
  );
}
