import axios from "axios"

/**
 * Http Service which can be used throughout the application
 * to make api & http calls.
 */
export class HttpService {
  
  /**
   * HttpService constructor
   * @param Function onSuccess
   * @param Function onError
   * @param {*} options
   */
  constructor(
    onRequest = null,
    onSuccess = null,
    onError = null,
    options = {}
  ) {
    this.options = options
    this.http = axios.create(options)

    // actions to take before call
    if (onRequest) this.http.interceptors.request.use(onRequest)

    // action to take after response.
    if (onSuccess && onError) {
      this.http.interceptors.response.use(onSuccess, onError)
    }
  }

  /**
   * Set base url for the service
   * @param string base
   */
  setBaseUrl(base) {
    this.http.defaults.baseURL = base
  }

  /**
   * Add new headers to the service object
   * @param {*} headers
   */
  attachHeaders(headers) {
    Object.assign(this.http.defaults.headers, headers)
  }

  /**
   * Remove headers from the object
   * @param {*} headerKeys
   */
  removeHeaders(headerKeys) {
    headerKeys.forEach(key => delete this.http.defaults.headers[key])
  }
}

export default HttpService
