import ProTable from "@ant-design/pro-table";

function ClientOrders({ order, setSelectedOrder }) {
  return (
    <ProTable
      //------------------------------------------------------------
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          sorter: true,
          valueType: "textarea",
        },
        {
          title: "Opportunity name",
          dataIndex: "description",
          sorter: true,
        },
        // {
        //     title: "Client",
        //     dataIndex: "client",
        //     render: (dom, entity) => {
        //         return (
        //             <h4>
        //                 {" "}
        //                 <Link key={1} to="#">
        //                     {entity.client.name}
        //                 </Link>
        //             </h4>
        //         );
        //     },
        // },

        // {
        //     title: "Status",
        //     dataIndex: "status",
        //     sorter: true,
        //     render: (dom, entity) => {
        //         let t = null;
        //         return <OrderStatus orderStatus={entity.status} />;
        //     },
        // },
        {
          title: "Materials",
          dataIndex: "totalSize",
          search: false,
          sorter: true,
          hideInForm: true,
          renderText: (val) => `${val} Sqf.`,
        },
        {
          title: "Amount",
          dataIndex: "totalPrice",
          search: false,
          sorter: true,
          hideInForm: true,
          renderText: (val) => `$ ${val}`,
        },
        {
          title: "Payment",
          dataIndex: "percent",
          search: false,
          sorter: true,
          hideInForm: true,
          renderText: (val) => `NA`,
        },
        // {
        //     title: "Sales Rep.",
        //     dataIndex: "User",
        //     sorter: true,
        //     render: (dom, entity) => {
        //         return (
        //             <Link key={1} to={`/account/center/${dom.id}`}>
        //                 {dom.name}
        //             </Link>
        //         );
        //     },
        // },

        {
          title: "Created On",
          sorter: true,
          search: false,
          dataIndex: "updatedAt",
          valueType: "dateTime",
        },
      ]}
      //-------------------------------------------------------------

      headerTitle={false}
      search={false}
      options={false}
      rowKey="id"
      dataSource={order?.client.orders}
      pagination={false}
      rowSelection={{
        onChange: (_, selectedRows) => {
          setSelectedOrder(selectedRows);
        },
      }}
    />
  );
}

export default ClientOrders;
