import { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ProFormText } from "@ant-design/pro-components";
import { Card, Alert, Button, message,Collapse,Modal,Skeleton } from "antd";
import marbleApi from "../../../services/http/marbleApi";
const { Panel } = Collapse;


function SearchFromAccount(props) {
  const { searchAccountPreview, setSearchAccountPreview, setClient, setZohoAccount } = props;
    const [account, setAccount] = useState(null);
    const [allAccounts, setAllAccounts] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [showAccounts, setshowAccounts] = useState(false);
    const [loading, setLoadnig] = useState(false);


  // search Account
    const searchAccount = async (domain) => {
    message.loading({
      content: "fetching...",
      key: "fetching",
      duration: 0,
    });
    try {
      const response = await marbleApi.http.post(
        marbleApi.urls.zoho.searchAccount,
        {
          data: domain,
        }
      );
        if (response?.length > 0) {
        setAllAccounts(response);
        message.success({
          content: "Account found",
          key: "fetching",
        });
        } else {
        message.warning({
          content: "no account found!",
          key: "fetching",
        });
        setAllAccounts([]);
        }
        setshowAccounts(true)
    } catch (error) {
      message.error({
        content: error?.message,
        key: "fetching",
      });
      return [];
    }
  };

  // fetch Contacts
    const fetchContacts = async (id) => {
        if (id) {
            try {
                setLoadnig(true)
              const response = await marbleApi.http.post(
                marbleApi.urls.zoho.fetchContacts,
                {
                  id,
                }
              );
                if (response?.length > 0) {
                setAllContacts(response);
              } else {
                setAllAccounts([]);
                }
                setLoadnig(false)
            } catch (error) {
              message.error(error);
              setAllAccounts([]);
              return [];
            }
        }
    };
    
      // Select Contact
    const selectContact = async (contact) => {
      try {
        const response = await marbleApi.http.post(
          marbleApi.urls.zoho.selectContact,
          {
            contact,
          }
        );
        if (response) {
            setClient(response);
            setZohoAccount(response.zohoAccountId)
            setSearchAccountPreview(false)
        }
      } catch (error) {
        message.error(error);
      }
    };

  return (
    <Modal
      visible={searchAccountPreview}
      title="Search Account"
          footer={null}
          onCancel={()=>setSearchAccountPreview(false)}
      width="60vw"
    >
      <ProFormText
        name="searchAccount"
        label="Account name"
        onChange={(e) => {
          setAccount(e.target.value);
        }}
        addonAfter={
          <>
            <Button
              type="primary"
              style={{ width: 30 }}
              icon={<SearchOutlined />}
              block
              onClick={() => searchAccount(account)}
            />
          </>
        }
      />
      <br></br>
      {showAccounts &&
        (allAccounts.length > 0 ? (
          <>
            <h3>Accounts</h3>
            <Card style={{ height: "400px", overflowY: "scroll" }}>
              <Collapse
                accordion
                onChange={(e) => {
                  fetchContacts(e);
                }}
              >
                {allAccounts?.map((item) => (
                  <Panel
                    header={
                      <>
                        <b>
                          <i>{item?.Account_Name}</i>
                        </b>
                        <br />
                        <i>{item?.Industry1}</i> | <i>{item?.Website}</i> |{" "}
                        <i>{item?.Estado_Unificado}</i>{" "}
                      </>
                    }
                    key={item.id}
                  >
                    {loading ? (
                      <Skeleton />
                    ) : allContacts.length > 0 ? (
                      <>
                        <h3>Contacts</h3>
                        <Collapse accordion>
                          {allContacts?.map((i) => (
                            <Panel
                              header={
                                <>
                                  <i>{i?.Full_Name}</i>{" "}
                                  <Button
                                    style={{ float: "right" }}
                                    onClick={() => selectContact(i)}
                                    type="link"
                                  >
                                    select
                                  </Button>
                                </>
                              }
                              key={i.id}
                            >
                              <div>
                                Name - <b>{i?.Full_Name}</b> / Email -{" "}
                                <b>{i?.Email || "NA"}</b> / phone -{" "}
                                <b>{i?.Phone}</b>
                                <br></br>
                                Industry - <b>{i?.Industria || "NA"}</b> / Owner
                                - <b>{i?.Owner.name || "NA"}</b>
                              </div>
                            </Panel>
                          ))}
                        </Collapse>
                      </>
                    ) : (
                      <p>This Account has no contacts linked</p>
                    )}
                  </Panel>
                ))}
              </Collapse>{" "}
            </Card>{" "}
          </>
        ) : (
          <Alert
            showIcon={false}
            className="new_order_column"
            type="error"
            banner
            message="No Account found!"
          />
        ))}
    </Modal>
  );
}

export default SearchFromAccount