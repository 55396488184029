/* eslint-disable react/jsx-no-target-blank */
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Table } from "antd";
import React from "react";

function Emails(props) {
  const data = [];
  const { order } = props;
  const columns = [
    {
      title: "Subject",
      tip: "Email Subject",
      dataIndex: "subject",
      sorter: true,
      render: (dom, entity) => {
        return <strong>{dom}</strong>;
      },
    },
    {
      title: "Body",
      dataIndex: "body",
      valueType: "textarea",
    },
    /* {
      title: "Viewed",
      dataIndex: "viewed",
      valueType: "textarea",
      render: (dom, entity) => {
        return (
          <>
            {dom === "yes" ? (
              <span>
                Yes &nbsp;
                <CheckCircleFilled style={{ color: "green" }} />
              </span>
            ) : (
              <span>
                No &nbsp;
                <ExclamationCircleFilled style={{ color: "grey" }} />
              </span>
            )}
          </>
        );
      },
    }, */
    {
      title: "Sent At",
      dataIndex: "createdAt",
      valueType: "textarea",
    },
    /*     {
      title: "Viewed At",
      dataIndex: "viewed_at",
      valueType: "textarea",
    }, */

    {
      title: "Attachments",
      dataIndex: "operation",
      key: "operation",
      valueType: "option",
      render: (dom, entity) =>
        entity.attachments.map((attachment) => {
          return (
            <a target={"_blank"} href={attachment.url}>
              {attachment.filename}
            </a>
          );
        }),
    },
  ];

  return (
    <Table
      pagination={false}
      loading={false}
      dataSource={order.emails}
      columns={columns}
    />
  );
}

export default Emails;
