import {
  CopyOutlined,
  DownloadOutlined,
  FileSyncOutlined,
  LeftOutlined,
  RightOutlined,
  SelectOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Card, Menu, message, Spin, Tooltip } from "antd";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function SalePDF({ order }) {
  // console.log(order?.saleOrder, "ORDERRRRRRRRRRRRRRRR");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setLoading(false);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const menu = (
    <Menu>
      <Menu.Item icon={<SelectOutlined />}>
        <a href={order?.saleOrder} target="_blank" rel="noreferrer">
          Open in new tab
        </a>
      </Menu.Item>
      <Menu.Item icon={<SyncOutlined />}>Create Sale Order PDF</Menu.Item>
    </Menu>
  );

  return (
    <Card
      title={
        <span>
          <FileSyncOutlined /> Sale Order Generated
        </span>
      }
      bordered={false}
      style={{}}
      extra={
        <>
          <Tooltip title="Copy Sale Order Link">
            <Button type="text">
              <CopyOutlined
                onClick={() => {
                  navigator.clipboard.writeText(order?.saleOrder);
                  message.success("Copied to clipboard");
                }}
              />
            </Button>
          </Tooltip>
          <Button type="text">
            <DownloadOutlined /> Download
          </Button>
          <Button
            type="link"
            onClick={() => {
              window.open(order?.saleOrder, "_blank");
            }}
          >
            <SelectOutlined /> Open
          </Button>

          {/* <Button type="link">
            <SendOutlined /> Send Sale Order
          </Button>
          <Dropdown overlay={menu}>
            <Button type="link">
              <MoreOutlined />
            </Button>
          </Dropdown> */}
        </>
      }
      actions={[
        <Button type="link" disabled={pageNumber <= 1} onClick={previousPage}>
          <LeftOutlined /> Previous Page
        </Button>,
        <p>
          Page {pageNumber} of {numPages}
        </p>,
        <Button
          type="link"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next Page <RightOutlined />
        </Button>,
      ]}
    >
      <div className="pdf-viewer">
        <Spin tip="Loading PDF" spinning={loading}>
          <Document
            className={"pdf-viewer"}
            file={order?.saleOrder}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Spin>
      </div>
    </Card>
  );
}

export default SalePDF;
