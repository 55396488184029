import { message } from "antd";
import { intersectionWith, isEqual } from "lodash";
import { updateCart } from "../helpers";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const CLEAR_CART = "CLEAR_CART";

const addProductToCart = (products, state) => {
  const updatedCart = [...state.cart];

  let existing = intersectionWith(updatedCart, products, isEqual);
  if (existing.length > 0) {
    existing.forEach((product) => {
      message.warning(
        `${product.name} | ${product.slabId} already exists in cart`
      );
    });
  } else {
    updatedCart.push(...products);
    updateCart(updatedCart);
    let extra =
      products.length > 1 ? `and ${products.length - 1} other products ` : "";
    message.success(`${products[0].name} ${extra} added to cart`);
  }

  return { ...state, cart: updatedCart };
};

const removeProductFromCart = (productId, state) => {
  const updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex(
    (item) => item.id === productId
  );
  updatedCart.splice(updatedItemIndex, 1);
  updateCart(updatedCart);
  return { ...state, cart: updatedCart };
};

const clearCart = (state) => {
  updateCart([]);
  return { ...state, cart: [] };
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return addProductToCart(action.product, state);
    case REMOVE_PRODUCT:
      return removeProductFromCart(action.productId, state);
    case CLEAR_CART:
      return clearCart(state);
    default:
      return state;
  }
};
